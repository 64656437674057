import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import supabase from "components/superBaseConfig";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MainLoader from "components/Loader";
import Typography from "@mui/material/Typography";
import { Button, Text } from "components";
import { MicOff, VideoOff } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoBase64EncodedValue from "../CareerAdvice/report";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
// import WebcamRecorder from "./WebcamRecorder";
import SubmitTestPopup from "./submitTestPopup";
import SpeechSection from "./textToSpeech";
import LiveInterviewInstruction from "./liveInterviewInstructions";
import { fetchInterviewPrepData } from "actions/InterviewPrepData";
import { fetchJobSeekerDashboarCounts } from "actions/JobSeekerDashboard";
import { fetchInterviewRequestCount } from "actions/InterviewRequestCount";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import WebcamRecorder from "./WebcamRecorder";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
var tempData = [];

const AudioSvg = () => {
  <div className="loaderAudio flex justify-center items-center h-[200px] rounded-[14px]">
    {" "}
    <svg
      id="wave"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 38.05"
    >
      <title>Audio Wave</title>
      <path
        id="Line_1"
        data-name="Line 1"
        d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
      />
      <path
        id="Line_2"
        data-name="Line 2"
        d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
      />
      <path
        id="Line_3"
        data-name="Line 3"
        d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
      />
      <path
        id="Line_4"
        data-name="Line 4"
        d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
      />
      <path
        id="Line_5"
        data-name="Line 5"
        d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
      />
      <path
        id="Line_6"
        data-name="Line 6"
        d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
      />
      <path
        id="Line_7"
        data-name="Line 7"
        d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"
      />
      <path
        id="Line_8"
        data-name="Line 8"
        d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"
      />
      <path
        id="Line_9"
        data-name="Line 9"
        d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"
      />
    </svg>
  </div>;
};
const LiveInterviewSection = ({
  open,
  setOpen,
  questionsArray,
  noOfQuestions,
  answersArray,
  handleStartClose3,
  selectedRole,
  selectedDuration,handleGetAdvice,
  questionsAnswersArray,
  setQuestionsAnswersArray,
  setQuestionsArray,
  setLiveInterviewScore,
  fullName,
  uploadReport,
  setClose,
  setVoiceSelected,
  setInstruction,
}) => {
  const user_id = useSelector(
    (state) => state.profilePersonalData.personalData["id"]
  );
  const dispatch = useDispatch();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(20 * 60);
  const [questionTimer, setQuestionTimer] = useState(20 * 60);
  const [questionTimerOn, setQuestionTimerOn] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [mainLive, setMainLive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [microphonePermission, setMicrophonePermission] = useState(false);
  const [cameraPermission, setCameraPermission] = useState(false);
  const [permissionError, setPermissionError] = useState("");
  const [blob, setBlob] = useState(null);
  const [openResult, setopenResult] = useState(false);
  const [qaPairs, setQAPairs] = useState([]);
  const [recordingText, setRecordingText] = useState(
    Array(noOfQuestions).fill("")
  );
  const [listening, setListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [speechStatus, setSpeechComplete] = useState(false);
  const questionAnswersArrayRef = useRef([{}]);
  // const [recording, setRecording] = useState(false);
  const videoRef = useRef(null);
  // const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [videoLink, setVideoLink] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [emotionalSummary, setEmotionalSummary] = useState(null);
  const [videoLinkPosted, setVideoLinkPosted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popupError, setPopupError] = useState(false);
  const job_id = useRef(null);
  const recognitionRef = useRef(null);
  const [videoFile, setVideoFile] = useState(null);
  const [interviewID, setInterviewID] = useState(null);
  const userVideo = useRef({});
  const hasMounted = useRef(false);
  const [mediaRecorderVideo, setMediaRecorderVideo] = useState(null);
  const handleClosePopup = () => {
    setPopupError(false);
  };
  useEffect(() => {
    // Warn the user if they try to leave the page or reload
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Most browsers will show a default message
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const handleStartAnswer = () => {
    setSpeechComplete(true);
  };
  const [webCamRecording, setWebcamRecording] = useState(true);

  const startRecordingStatus = () => {
    // Logic to start recording
    setWebcamRecording(true);
  };

  const stopRecordingStatus = () => {
    setWebcamRecording(false);
  };
  useEffect(() => {
    if (!webCamRecording) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((error) =>
          console.error("Error stopping camera access:", error)
        );
    }
  }, [webCamRecording]);
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true; // Continuous listening
      recognition.interimResults = true; // Get interim results

      recognition.onstart = () => {
        setListening(true);
      };

      recognition.onend = () => {
        setListening(false);
      };

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        const updatedRecordingText = [...recordingText];
        updatedRecordingText[currentQuestion] = transcript;
        console.log("Transcript:", transcript);
        recognitionRef.current = transcript;
        setRecordingText(updatedRecordingText);
      };

      setRecognition(recognition);
    }
  }, [currentQuestion, noOfQuestions, recordingText]);

  const handleStop = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsPaused(false);
  };

  const startRecording = () => {
    if (userVideo.current.srcObject) {
      console.log("started recording");
      // //console.log("in if", userVideo.current.srcObject)
      const recorderVideo = new MediaRecorder(userVideo.current.srcObject);
      let videoData = [];
      recorderVideo.ondataavailable = (event) => {
        if (event.data.size > 0) {
          if (event.data.type.includes("video")) {
            videoData.push(event.data);
          }
        }
      };
      recorderVideo.onstop = async () => {
        const videoBlob = new Blob(videoData, { type: "video/webm" });
        const videoFile = new File([videoBlob], "video.webm", {
          type: "video/webm",
        });
        setVideoFile(videoFile);
      };

      setMediaRecorderVideo(recorderVideo);
      recorderVideo.start();
      // setRecording(true);
    } else {
      console.error("srcObject is null, cannot start recording.");
    }
  };

  const stopRecording = () => {
    //console.log("recoding stopped")
    if (userVideo.current && userVideo.current.srcObject) {
      console.log("stopped recording");
      mediaRecorderVideo.stop();
    } else {
      console.error(
        "userVideo.current or its srcObject is null, cannot stop recording.",
      );
    }
  };

  const handleAnswerUpload = () => {
    stopRecording();
    handleFileUpload();
  };

  const handleFileUpload = async () => {
    const userObj = await supabase.auth.getUser();
    const userEmail = userObj.data.user.email;
    const now = new Date();
    const formattedDate = formatDateToString(now);
    if (!interviewID) {
      setInterviewID(formattedDate);
    }

    const filePathVideo = `${userEmail}/${interviewID ? interviewID : formattedDate}/answerVideo-${currentQuestion}`;

    try {
      const videoUpload = await supabase.storage
        .from("interviewprep-storage-bucket")
        .upload(filePathVideo, videoFile);

      if (videoUpload.error) {
        console.error("Video upload error:", videoUpload.error);
      } else {
        console.log('Video file uploaded successfully');
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  function formatDateToString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getNextQuestion = async () => {
    const currentQuestionAnswerPair = {
      question: questionsArray[questionsArray.length - 1],
      answer: recognitionRef.current || "I dont know",
    };

    console.log("Current Question Answer Pair:", currentQuestionAnswerPair);
    console.log(recordingText);

    setQAPairs([...qaPairs, currentQuestionAnswerPair]);
    setQuestionsAnswersArray((prev) => [...prev, currentQuestionAnswerPair]);

    const oldQuestions = questionAnswersArrayRef.current;
    questionAnswersArrayRef.current = [
      ...oldQuestions,
      currentQuestionAnswerPair,
    ];
    const apiUrl = `${process.env.REACT_APP_API_URL}/interview_questions?role=${selectedRole}&duration=${selectedDuration}`;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question_answer_pair: questionAnswersArrayRef.current,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setQuestionsArray((prevQ) => [...prevQ, data.questions[0]]);
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      })
      .catch((error) =>
        console.error("Error fetching interview questions:", error)
      );
  };
  const handleNextQuestion = () => {
    if (recognition) {
      recognition.stop();
    }
    handleAnswerUpload();
    setopenResult(true);
    setSpeechComplete(false);
    getNextQuestion();
    setQuestionTimerOn(false);
    setQuestionTimer(2 * 60);
    handleStop();
  };

  const handleQuestionTimer = () => {
    startRecording();
    setQuestionTimerOn(true);
    setQuestionTimer(2 * 60);
    if (recognition) {
      recognition.start();
    }
  };


  useEffect(() => {
    if (timeRemaining > 0) {
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerInterval);
            handleGetAdvice();;
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [timeRemaining]);

  // useEffect(() => {
  //   let timer;
  //   if (timerOn && timeRemaining > 0) {
  //     timer = setTimeout(() => {
  //       setTimeRemaining((prevTime) => prevTime - 1);
  //     }, 1000);
  //   } else {
  //     setCurrentQuestion(noOfQuestions);
  //     setTimerOn(false);
  //     setCurrentQuestion(0);
  //   }

  //   return () => clearTimeout(timer);
  // }, [timerOn, timeRemaining]);

  useEffect(() => {
    if (questionTimer === 0) {
      handleNextQuestion();
    }
    let timer;
    if (questionTimerOn && questionTimer > 0) {
      timer = setTimeout(() => {
        setQuestionTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setQuestionTimerOn(false);
    }

    return () => clearTimeout(timer);
  }, [questionTimerOn, questionTimer]);

  const handleStartCloseFeedback = () => {
    setMainLive(false);
    setCurrentQuestion(0);
    setInstruction(false);
    setVoiceSelected(false);
    setQuestionsArray([]);
    handleStartClose3();
  };

  const [openSubmitTest, setOpenSubmitTest] = useState(false);

  const handleSubmitTest = () => {
    console.log("Submit Test");
    handleFileUpload();
    console.log("Question Answers Array:");
    postVideoLink();
    console.log("Question fweflweofkwe:")
    setSpeechComplete(true);
    setOpenSubmitTest(true);
    setRecordingText(Array(noOfQuestions).fill(""));
    setQuestionsAnswersArray([{}]);
  };

  const handleCloseSubmitTest = () => {
    stopRecordingStatus();
    setOpenSubmitTest(false);
  };

  const buildPDF = async (job_id, emotionalSummaryVar) => {
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    try {
      const docData = {
        pageMargins: [40, 80, 40, 40],
        header: [
          {
            text: "\n\n\n\n\n\n\n",

            columns: [
              {
                image: logoBase64EncodedValue,
                cover: {
                  width: 50,
                  height: 50,
                  valign: "top",
                  align: "right",
                },
              },
              {
                text: "Interview Report",
                color: "#314CA3",
                fontSize: 18,
                bold: true,
                margin: [0, 15, 0, 0],
                alignment: "left",
              },
            ],
            columnGap: 180,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 800,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
        ],

        content: [
          {
            text: [
              "Full Name: ",
              { text: fullName, style: "bold" },
              "\n\n",
              "Role: ",
              { text: selectedRole, style: "bold" },
              "\n\n",
              "Interview type: ",
              { text: "Live Inteview", style: "bold" },
              "\n\n",
              "Duration: ",
              { text: selectedDuration, style: "bold" },
              " minutes\n\n",
              "Date and Time: ",
              { text: date + " " + time, style: "bold" },
              "\n\n",
            ],
            margin: [0, 20, 0, 0],
          },
          {
            text: "Score Analysis\n",
            style: "bold",
            margin: [0, 20, 0, 5],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 500,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
          {
            text: emotionalSummaryVar,
            margin: [0, 20, 0, 5],
          },
          
        ],
        styles: {
          bold: {
            bold: true,
          },
          green: {
            color: "green",
          },
        },
      };
      // Creating PDF using pdfMake
      const pdfDoc = pdfMake.createPdf(docData);
      pdfDoc.getBlob(async (blob) => {
        await uploadReport(job_id, blob);
      });
    } catch (e) {
      //console.log(e);
    }
  };

  const handleDownloadRecording = async () => {
    /* if (blob) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recording.webm";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a); // Remove the temporary anchor element
    } else {
      console.error("No recorded video available to download.");
      // Optionally, you can provide feedback to the user if there's no recorded video available.
    } */
    //console.log("Download PDF");
    //console.log(job_id.current);
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download_interview_report?user_id=${userId}&job_id=${job_id.current}`
      );
      const blob = await response.blob();
      //console.log(blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "InterviewPreparationReport.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      //console.log(e);
    }
  };

  // callback function to recieve the video link
  const handleVideoLinkChange = (link) => {
    //console.log("Video link received:", link);
    setVideoLink(link);
  };
  const handleVideoDataChange = (data) => {
    //console.log("Video data received:", data);
    setVideoData(data);
  };

  // Function to post video link to the backend
  const postVideoLink = async () => {
    try {
      setLoading(true);
      const userObj = await supabase.auth.getUser();
      const userEmail = userObj.data.user.email;
      const videoLinks = [];
      for (let i = 0; i < noOfQuestions; i++) {
        const videoLink = await supabase.storage
          .from("interviewprep-storage-bucket")
          .createSignedUrl(`${userEmail}/${interviewID}/answerVideo-${i}`, 3600);
        console.log(videoLink);
        videoLinks.push(videoLink);
      }
      const data = {
        created_at: new Date().toISOString(),
        interview_type: "Live Interview",
        role: selectedRole,
        performance: "null",
        date: new Date(),
        duration: 1,
        report: [],
        user_id: userObj.data.user.id,
        job_id: null,
      };
      console.log("Video links:", videoLinks);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ video_urls: videoLinks, details: data }),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/post_interviewprep_assets`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to post video link");
      }

      console.log("Video link posted successfully");
      setVideoLinkPosted(true); // Set the state to indicate successful posting
      dispatch(fetchInterviewPrepData(user_id));
      dispatch(fetchJobSeekerDashboarCounts(user_id));
      dispatch(fetchInterviewRequestCount(user_id));

    } catch (error) {
      console.error("Error posting video link:", error);
    }
  };

  useEffect(() => {
    const fetchEmotionalSummary = async () => {
      try {
        console.log(questionAnswersArrayRef.current);
        const url = `${process.env.REACT_APP_API_URL}/live_interview_grading`;

        const requestBody = {
          role: selectedRole,
          user_id: user_id,
          question_answer_list: questionAnswersArrayRef.current,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch emotional summary");
        }

        const data = await response.json();
        const emotionalSummaryVar = data["report"];
        setEmotionalSummary(emotionalSummaryVar);
        job_id.current = data["job_id"];
        setLoading(false);
        await buildPDF(job_id.current, emotionalSummaryVar);
      } catch (error) {
        setEmotionalSummary(
          "The emotional summary could not be retrieved as no audio was detected.\n"
        );
        console.error("Error fetching emotional summary:", error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (videoLinkPosted && user_id) {
      // Ensure user_id is available
      fetchEmotionalSummary();
    }
  }, [videoLinkPosted, user_id]);

  // useEffect(() => {
  //   postVideoLink(videoLink, videoData);
  // }, [videoLink, videoData]);

  const [openPermissionPopup, setOpenPermissionPopup] = useState(false);
  const [accessGrantedPopup, setAccessGrantedPopup] = useState(false);
  const [accessDeniedPopup, setAccessDeniedPopup] = useState(false);
  const [permissonBlock, setPermissionBlock] = useState(false);
  const [toaststate, setToasterState] = useState(false);
  async function checkCameraPermission() {
    try {
      if ("permissions" in navigator) {
        const result = await navigator.permissions.query({ name: "camera" });

        switch (result.state) {
          case "granted":
            console.log("Camera permission is granted.");
            setAccessGrantedPopup(true);
            setAccessDeniedPopup(false);
            setOpenPermissionPopup(false);
            setMicrophonePermission(true);
            setCameraPermission(true);
            setTimerOn(true);
            setMainLive(true);
            break;
          case "prompt":
            console.log("Camera permission is promptable.");
            setAccessGrantedPopup(false);
            setAccessDeniedPopup(false);
            break;
          case "denied":
            console.log("Camera permission is denied.");
            setAccessGrantedPopup(false);
            setAccessDeniedPopup(true);
            break;
          default:
            console.log("Unknown camera permission state:", result.state);
            setAccessGrantedPopup(false);
            setAccessDeniedPopup(false);
            break;
        }
      } else {
        console.warn("Permissions API is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error checking camera permissions:", error);
    }
  }

  const handleStartAssessment = () => {
    setOpenPermissionPopup(true);
    checkCameraPermission();
  };

  const closePermissionPopup = () => {
    setOpenPermissionPopup(false);
    setAccessGrantedPopup(false);
    setAccessDeniedPopup(false);
  };

  const requestCameraPermission = async () => {
    try {
      const camera = await navigator.mediaDevices.getUserMedia({ video: true });
      const microphone = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      microphone.getTracks().forEach((track) => track.stop());
      camera.getTracks().forEach((track) => track.stop());
      setMicrophonePermission(true);
      setCameraPermission(true);
    } catch (error) {
      console.error("Error accessing microphone or camera:", error);
      // alert("Error accessing microphone or camera");
      setPopupError(true);
      setPermissionBlock(true);
      setMicrophonePermission(false);
      setCameraPermission(false);
      setPermissionError(
        "Please enable microphone and camera permissions to proceed."
      );
      setMainLive(false); // Set mainLive to false if there's an error
      return;
    }
  };
  const ReCheckCameraPermission = async () => {
    try {
      const camera = await navigator.mediaDevices.getUserMedia({ video: true });
      const microphone = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      microphone.getTracks().forEach((track) => track.stop());
      camera.getTracks().forEach((track) => track.stop());
      setOpenPermissionPopup(false);
      setAccessGrantedPopup(true);
      startAssesment();
    } catch (error) {
      notify();
      setToasterState(true);
    }
  };

  const notify = () =>
    toast.info(
      "Couldn't access your camera,Check the permissions and try again",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      }
    );

  const startAssesment = () => {
    setMicrophonePermission(true);
    setCameraPermission(true);
    setTimerOn(true);
    setMainLive(true);
    setOpenPermissionPopup(false);
    setOpen(true);
  };


  const renderButtons = () => {
    if (questionTimerOn) {
      return (
        <Button
          className="w-[150px] h-[45px] text-center"
          onClick={currentQuestion + 1 === noOfQuestions ? handleSubmitTest : handleNextQuestion}
        >
          {currentQuestion + 1 === noOfQuestions ? 'Submit' : 'Done'}
        </Button>
      );
    } else if (!speechStatus) {
      return (
        <>
          <button
            className="w-[150px] h-[45px] text-center rounded-[12px]"
            style={{
              color: "blue",
              backgroundColor: "#fff",
              border: "2px solid blue",
            }}
          >
            Answer
          </button>
          
        </>
      );
    } else {
      return (
        <>
          <Button
            className="w-[150px] h-[45px] text-center"
            onClick={handleQuestionTimer}
          >
            Answer
          </Button>
        </>
      );
    }
  };

  return (
    <>
      {currentQuestion <= noOfQuestions - 1 ? (
        <Dialog
          fullScreen
          open={open}
          // onClose={handleStartCloseFeedback}
          disableBackdropClick={true}
        >
          {mainLive ? (
            <div className="h-full w-full flex flex-col">
              {permissionError && (
                <div className="permission-error">
                  <p>{permissionError}</p>
                </div>
              )}
              <AppBar style={{ backgroundColor: "#314ca3" }}>
                <Toolbar className="flex justify-between items-center">
                  <Text className="text-xl sm:text-[14px]">
                    Please answer all the five questions
                  </Text>
                  <p className="text-xl sm:text-[14px] font-medium">
                    Time left:{" "}
                    {String(Math.floor(timeRemaining / 60)).padStart(2, "0")} :{" "}
                    {String(timeRemaining % 60).padStart(2, "0")}
                  </p>
                </Toolbar>
              </AppBar>
              <div
                style={{ backgroundColor: "#e5e8f3" }}
                className="w-[80vw] h-auto pb-[40px] flex flex-col self-center mt-[120px] rounded-[14px]"
              >
                <div className="flex justify-end mr-[40px] mt-[20px]">
                  <Text>
                    Attempted questions: {currentQuestion}/{noOfQuestions}
                  </Text>
                </div>
                <SpeechSection
                  index={currentQuestion}
                  handleStartAnswer={handleStartAnswer}
                  text={questionsArray[currentQuestion]}
                />
                <div className="w-[95%] mt-[40px] self-center">
                  <Text className="pb-[20px]">Your answer:</Text>
                  {questionTimerOn ? (
                    <AudioSvg />
                  ) : (
                    <textarea
                      readOnly
                      className="w-full rounded-[10px] mt-[10px] sm:text-[14px] text-gray-500 text-md min-h-[200px]"
                    />
                  )}
                </div>
                <div className="flex justify-between w-[95%] self-center mt-[50px]">
                  {renderButtons()}
                  {questionTimerOn ? (
                    <Text>
                      Remaining Time: {Math.floor(questionTimer / 60)}:
                      {questionTimer % 60}{" "}
                    </Text>
                  ) : null}
                </div>

                <SubmitTestPopup
                  setOpenResult={setopenResult}
                  openSubmitTest={openSubmitTest}
                  SubmitTestClose={handleCloseSubmitTest}
                  handleGetAdvice={handleNextQuestion}
                  startRecordingStatus={startRecordingStatus}
                  stopRecordingStatus={stopRecordingStatus}
                  webCamRecording={webCamRecording}
                />
              </div>
              <div className="w-full flex flex-row justify-end">
                <div
                  style={{
                    width: "100%",
                    margin: "4px",
                    width: "200px",
                    height: "200px",
                    marginRight: "70px",
                  }}
                >
                  <WebcamRecorder
                    startRecordingStatus={startRecordingStatus}
                    stopRecordingStatus={stopRecordingStatus}
                    onVideoLinkChange={handleVideoLinkChange}
                    onVideoDataChange={handleVideoDataChange}
                    webCamRecording={webCamRecording}
                  />
                  {videoLink && <div>Video Link: {videoLink}</div>}
                  {videoData && <div>Video Data: {videoData}</div>}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-gray-100 h-full">
              <AppBar
                style={{ backgroundColor: "#314ca3" }}
                sx={{ fontSize: { sm: "12px" } }}
              >
                <Toolbar>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                    className="sm:text-[1px]"
                  >
                    <Text className="text-xl sm:text-[14px]">
                      Please Read Instruction Carefully
                    </Text>
                  </Typography>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleStartCloseFeedback}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <div className=" overflow-scroll pb-[40px] pt-[50px] text-gray-500 gap-[50px] h-[100%] flex flex-col justify-center items-center">
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    opacity: popupError ? 0.25 : 1,
                  }}
                  className="pl-[4.5%] w-[50vw] sm:w-[80vw] h-auto pb-[40px] v-[50vw]"
                >
                  <Text
                    className="text-xl pb-[40px] pt-[20px] sm:text-sm pl-[4%]"
                    size="txtInterMedium20"
                  >
                    Instructions:
                  </Text>
                  <div className="flex flex-col  justify-around  gap-[30px]">
                    <div className="flex flex-row items-center mb-[20px] gap-[30px] pl-[6%]">
                      <img
                        className="h-[45px] w-[45px]"
                        src="images/wifiIcon.svg"
                      />
                      <div>
                        {" "}
                        <Text
                          style={{ color: "#021e8d" }}
                          className="text-[18px] "
                        >
                          Internet connection is Required
                        </Text>
                        <Text>
                          Ensure that you have a stable Internet connection
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-row items-center mb-[20px] gap-[30px] pl-[6%]">
                      <img
                        className="h-[45px] w-[45px]"
                        src="images/micIcon.svg"
                      />
                      <div>
                        <Text
                          style={{ color: "#021e8d" }}
                          className="text-[18px]"
                        >
                          Microphone is Required
                        </Text>
                        <Text>
                          Permission for Microphone is required for the
                          assessment
                        </Text>
                      </div>
                    </div>
                    <div className="flex  flex-row items-center gap-[30px] pl-[6%] pb-[30px] ">
                      <img
                        className="h-[45px] w-[45px]"
                        src="images/cameraIcon.svg"
                      />
                      <div>
                        {" "}
                        <Text
                          style={{ color: "#021e8d" }}
                          className="text-[18px]"
                        >
                          Camera is required
                        </Text>
                        <Text>
                          Permission for camera is required for the assessment
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                    <hr class="border-t-4 border-black w-[95%]" />
                  </div>
                  <ul className="list-decimal w-full space-y-5 sm:text-[10px] pl-[9.5%] pt-[20px]">
                    <li className="mb-5">Duration:{noOfQuestions * 4}</li>
                    <li className="mb-5">
                      Number of questions:{noOfQuestions}
                    </li>{" "}
                    <li className="mb-5">
                      Do not communicate with other participants during the
                      exam.
                    </li>
                    <li className="mb-5">
                      Once submitted, you cannot return to previous questions.
                    </li>
                  </ul>
                </div>
                <Button
                  onClick={handleStartAssessment}
                  className="w-[200px] h-[40px]"
                >
                  Start Assessment
                </Button>
              </div>
            </div>
          )}
        </Dialog>
      ) : (
        <Dialog maxWidth="xl" open={openResult}>
          <div>
            {loading ? (
              <MainLoader />
            ) : (
              <>
                {/* <div>
                  <Text className="text-center pt-[20px] text-blue_gray-400 text-xl sm:text-sm">
                    Result for you
                  </Text>
                </div> */}

                <div className="flex flex-row justify-center mt-[30px]">
                  <div className="flex justify-between items-center w-[90%]">
                    <Text className=" text-blue_gray-400 text-xl sm:text-sm">
                      Result
                    </Text>
                 
                  </div>
                </div>

                <div className="flex flex-row justify-center mt-[30px]">
                  <textarea
                    readOnly
                    rows={23}
                    cols={100}
                    placeholder="Charlene"
                    maxLength={300}
                    className="w-[90%] sm:h-[300px]"
                    value={emotionalSummary ? emotionalSummary + "\n\n" : ""}
                  />
                </div>

                <div className="flex flex-row justify-center mt-[30px] mb-[30px]">
                  <div className="flex justify-between w-[90%]">
                  <button style={{border:"2px solid #314ca3"}}
                      onClick={handleDownloadRecording}
                      className="flex h-[45px] w-[150px] rounded-[10px] flex justify-around items-center sm:h-[30px] sm:w-[120px] sm:text-[12px]"
                    >
                      <Text>Download</Text>
                      <img
                        className="octicon-download"
                        alt="Octicon download"
                        src="https://cdn.animaapp.com/projects/65245b020103026f6d371b65/releases/6585a2cf79a36e6414abe303/img/octicon-download-16.svg"
                      />
                    </button>
                    <Button
                      className="flex h-[45px] w-[150px] sm:h-[30px] sm:w-[90px] sm:text-[12px] rounded-[10px] flex justify-around items-center"
                      onClick={handleStartCloseFeedback}
                    >
                      Exit
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
      <LiveInterviewInstruction
        openPermissionPopup={openPermissionPopup}
        startAssesment={startAssesment}
      />
    </>
  );
};

export default LiveInterviewSection;
