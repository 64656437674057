import { Button } from "components";
import AlertModal from "components/AlertModal";
import ProfessionalAwards from "pages/JobSeekerDashBoardPages/ProfileProfessional/Awards";
import CertificatesProfessional from "pages/JobSeekerDashBoardPages/ProfileProfessional/Certifications";
import ProfessionalCourse from "pages/JobSeekerDashBoardPages/ProfileProfessional/Courses";
import ProfessionalDeclaration from "pages/JobSeekerDashBoardPages/ProfileProfessional/Declaration";
import EducationProfessional from "pages/JobSeekerDashBoardPages/ProfileProfessional/Education";
import ProfessionalExtractForm from "pages/JobSeekerDashBoardPages/ProfileProfessional/ProfessionalExtractfrom";
import IntrestProfessional from "pages/JobSeekerDashBoardPages/ProfileProfessional/Intrests";
import ProfessionalOrganisation from "pages/JobSeekerDashBoardPages/ProfileProfessional/InterpersonalSkills";
import LanguageProfessional from "pages/JobSeekerDashBoardPages/ProfileProfessional/Languages";
import ProfessionalProject from "pages/JobSeekerDashBoardPages/ProfileProfessional/Projects";
import ProfessionalPublication from "pages/JobSeekerDashBoardPages/ProfileProfessional/Publications";
import ProfessionalReference from "pages/JobSeekerDashBoardPages/ProfileProfessional/Reference";
import SkillsProfessional from "pages/JobSeekerDashBoardPages/ProfileProfessional/TechnicalSkills";
import ProfessionalSocialLinks from "pages/JobSeekerDashBoardPages/ProfileProfessional/SocialLinks";
import WorkExperienceProfessional from "pages/JobSeekerDashBoardPages/ProfileProfessional/WorkExperience";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { ProfileHeader2 } from "pages/JobSeekerDashBoardPages/ProfileHeader2";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useState, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Prompt from "./Prompt";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileProfessionalData } from "actions/ProfileProfessionalData";

const Professional = () => {
  const [datachanged, setdataChanged] = useState(false);
  const handleDataUpdate = () => {
    setdataChanged(true);
  };
  const [success, setSuccess] = useState(false);
  const [userPersonalData, setUserPersonalData] = useState({
    Name: undefined,
    "Phone number": undefined,
    Email: undefined,
    Designation: undefined,
    "Experience in years": undefined,
    "College or University 1 with degree": undefined,
    "College or University 2 with degree": undefined,
    "College or University 3 with degree": undefined,
    School: undefined,
    City: undefined,
    State: undefined,
    Country: undefined,
    "Technical Skills": undefined,
    "Interpersonal Skills": undefined,
    Languages: undefined,
    "Project Lists": undefined,
    GitHub: undefined,
    LinkedIn: undefined,
    Courses: undefined,
    Awards: undefined,
    Publication: undefined,
    Reference: undefined,
    Declaration: undefined,
    Certificates: undefined,
    "Role 1 with Organization Name": undefined,
    "Role 2 with Organization Name": undefined,
    "Role 3 with Organization Name": undefined,
    "Interests and hobbies": undefined,
  });

  const [dataFromChild, setDataFromChild] = useState("Fill in Details");
  const EmptyResumeData = {
    Name: undefined,
    "Phone number": undefined,
    Email: undefined,
    Designation: undefined,
    "Experience in years": undefined,
    "College or University 1 with degree": undefined,
    "College or University 2 with degree": undefined,
    "College or University 3 with degree": undefined,
    School: undefined,
    City: undefined,
    State: undefined,
    Country: undefined,
    "Technical Skills": undefined,
    "Interpersonal Skills": undefined,
    Languages: [],
    "Project Lists": [],
    GitHub: undefined,
    LinkedIn: undefined,
    Courses: undefined,
    Awards: undefined,
    Publication: undefined,
    Reference: undefined,
    Declaration: undefined,
    Certificates: undefined,
    "Role 1 with Organization Name": undefined,
    "Role 2 with Organization Name": undefined,
    "Role 3 with Organization Name": undefined,
    "Interests and hobbies": undefined,
  };

  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
    )
  );
  const user_id = userData.user.id;

  const { profileProfessionalData } = useSelector(
    (state) => state.profileProfessionalReducer
  );
  const [workExperience, setWorkExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [projects, setProjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [awards, setAwards] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [publications, setPublications] = useState([]);
  const [references, setReferences] = useState([]);
  const [declarations, setDeclarations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const dispatch = useDispatch();
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  // function convertData(originalResume) {
  async function convertData(originalResume) {
    let convertedResume = {};

    // Awards
    if (originalResume?.awards?.[0]?.awards?.[0]) {
      const awardsArray = originalResume.awards[0].awards[0];

      if (typeof awardsArray === "string") {
        convertedResume["awards"] = awardsArray.split(",");
      } else {
        convertedResume["awards"] = [];
      }
    } else {
      convertedResume["awards"] = [];
    }

    // Courses
    if (originalResume && originalResume.courses && originalResume.courses[0] && originalResume.courses[0].courses) {
      const coursesArray = originalResume.courses[0].courses[0];
  
      if (Array.isArray(coursesArray) && coursesArray.length > 0) {
        convertedResume["courses"] = coursesArray[0].split(",");
      } else if (typeof coursesArray === "string") {
        convertedResume["courses"] = coursesArray.split(",");
      } else {
        convertedResume["courses"] = [];
      }
    } else {
      convertedResume["courses"] = [];
    }
  

    // Projects
    if (originalResume && originalResume.projects && originalResume.projects[0]) {
      const projectsArray = originalResume.projects[0];
      
      convertedResume["projects"] = [];
    
      for (let i = 0; i < (projectsArray.project_name ?? []).length; i++) {
        const project = {
          projectName: projectsArray.project_name[i],
          projectDescription: projectsArray.description[i],
          projectLink: projectsArray.reference[i],
          technologiesUsed: projectsArray.skills_technology[i],
        };
        convertedResume["projects"].push(project);
      }
    } else {
      convertedResume["projects"] = [];
    }

    // Publications
    if (originalResume && originalResume.publications && originalResume.publications[0] && originalResume.publications[0].publications) {
      const publications = originalResume.publications[0].publications;
      
      if (Array.isArray(publications) && publications.length > 0) {
        convertedResume["publications"] = publications;
      } else if (typeof publications === "string" && publications.trim().length > 0) {
        convertedResume["publications"] = [publications];
      } else {
        convertedResume["publications"] = [];
      }
    } else {
      convertedResume["publications"] = [];
    }

    // Work Experience
    // if (originalResume?.work_experience?.[0]) {
    //   convertedResume["work_experience"] = [];
    //   for (
    //     let i = 0;
    //     i < (originalResume.work_experience[0].org_company_name ?? []).length;
    //     i++
    //   ) {
    //     convertedResume["work_experience"].push({
    //       company: originalResume.work_experience[0].org_company_name[i],
    //       description: originalResume.work_experience[0].description[i],
    //       position: originalResume.work_experience[0].role[i],
    //       startDate: originalResume.work_experience[0].start_date[i],
    //       endDate: originalResume.work_experience[0].end_date[i],
    //     });
    //   }
    // }

    // console.log("Full originalResume object:", originalResume);


    // Work Experience
if (originalResume && originalResume.work_experience && originalResume.work_experience[0]) {
  const workExperienceArray = originalResume.work_experience[0];

  convertedResume["work_experience"] = [];

  // Loop through the length of the available data
  const length = workExperienceArray.org_company_name?.length ?? 0;
  for (let i = 0; i < length; i++) {
    const workExperience = {
      company: workExperienceArray.org_company_name[i] || "",
      description: workExperienceArray.description[i] || "",
      position: workExperienceArray.role[i] || "",
      startDate: workExperienceArray.start_date[i] || "",
      endDate: workExperienceArray.end_date[i] || ""
    };

    // Add the work experience entry if it has any non-empty fields
    if (workExperience.company || workExperience.description || workExperience.position || workExperience.startDate || workExperience.endDate) {
      convertedResume["work_experience"].push(workExperience);
    }
  }
} else {
  convertedResume["work_experience"] = [];
}


    // Education
    // if (originalResume?.education?.[0]) {
    //   convertedResume["education"] = [];
    //   for (
    //     let i = 0;
    //     i < (originalResume.education[0].college_uni_name ?? []).length;
    //     i++
    //   ) {
    //     convertedResume["education"].push({
    //       institution: originalResume.education[0].college_uni_name[i],
    //       degree: originalResume.education[0].degree[i],
    //       percentage: originalResume.education[0].percent[i],
    //     });
    //   }
    // }



    // Education
if (originalResume && originalResume.education && originalResume.education[0]) {
  convertedResume["education"] = [];
  
  const educationArray = originalResume.education[0];

  // Determine the number of entries based on a consistent key
  const length = educationArray.college_uni_name?.length || 0;

  for (let i = 0; i < length; i++) {
    const educationEntry = {
      institution: educationArray.college_uni_name[i] || "",
      degree: educationArray.degree[i] || "",
      percentage: educationArray.percent?.[i] ||"",
    };

    // Only push the entry if it has meaningful data
    if (educationEntry.institution || educationEntry.degree) {
      convertedResume["education"].push(educationEntry);
    }
  }
} else {
  convertedResume["education"] = [];
}


    // Certificates
    if (originalResume && originalResume.certificates && originalResume.certificates[0] && originalResume.certificates[0].certificates) {
      const certificatesArray = originalResume.certificates[0].certificates[0];
      
      if (Array.isArray(certificatesArray) && certificatesArray.length > 0) {
        convertedResume["certificates"] = certificatesArray[0].split(",");
      } else if (typeof certificatesArray === "string") {
        convertedResume["certificates"] = certificatesArray.split(",");
      } else {
        convertedResume["certificates"] = [];
      }
    } else {
      convertedResume["certificates"] = [];
    }
    

    // References
    if (originalResume && originalResume.references && originalResume.references[0] && originalResume.references[0].reference) {
      const reference = originalResume.references[0].reference;
      
      if (Array.isArray(reference) && reference.length > 0) {
        convertedResume["references"] = reference;
      } else if (typeof reference === "string" && reference.trim().length > 0) {
        convertedResume["references"] = [reference];
      } else {
        convertedResume["references"] = [];
      }
    } else {
      convertedResume["references"] = [];
    }

    // Interests
    if (originalResume?.interests?.[0]?.interests?.[0]) {
      const interestsArray = originalResume.interests[0].interests[0];

      if (typeof interestsArray === "string") {
        convertedResume["interests"] = interestsArray.split(",");
      } else {
        convertedResume["interests"] = [];
      }
    } else {
      convertedResume["interests"] = [];
    }

    // Declarations
    if (originalResume && originalResume.declarations && originalResume.declarations[0] && originalResume.declarations[0].declaration) {
      const declaration = originalResume.declarations[0].declaration;
      
      if (Array.isArray(declaration) && declaration.length > 0) {
        convertedResume["declarations"] = declaration;
      } else if (typeof declaration === "string" && declaration.trim().length > 0) {
        convertedResume["declarations"] = [declaration];
      } else {
        convertedResume["declarations"] = [];
      }
    } else {
      convertedResume["declarations"] = [];
    }

    // Interpersonal skills
    if (originalResume?.interpersonal_skills?.[0]?.interpersonal_skills?.[0]) {
      const interpersonalSkillsArray = originalResume.interpersonal_skills[0].interpersonal_skills[0];

      if (typeof interpersonalSkillsArray === "string") {
        convertedResume["interpersonal_skills"] = interpersonalSkillsArray.split(",");
      } else {
        convertedResume["interpersonal_skills"] = [];
      }
    } else {
      convertedResume["interpersonal_skills"] = [];
    }

    // Resume objective
    if (originalResume?.resume_objective?.[0]?.resume_objective) {
      convertedResume["resumeObjective"] =
        originalResume.resume_objective[0].resume_objective;
    }

    // Languages
    if (originalResume && originalResume.languages && originalResume.languages[0] && originalResume.languages[0].languages) {
      const languagesArray = originalResume.languages[0].languages;
    
      if (Array.isArray(languagesArray) && languagesArray.length > 0) {
        convertedResume["languages"] = languagesArray;
      } else {
        convertedResume["languages"] = [];
      }
    } else {
      convertedResume["languages"] = [];
    }

    // Technical skills
    if (originalResume && originalResume.technical_skills && originalResume.technical_skills[0] && originalResume.technical_skills[0].technical_skills) {
      const technicalSkillsArray = originalResume.technical_skills[0].technical_skills;
    
      if (Array.isArray(technicalSkillsArray) && technicalSkillsArray.length > 0) {
        convertedResume["technical_skills"] = technicalSkillsArray;
      } else {
        convertedResume["technical_skills"] = [];
      }
    } else {
      convertedResume["technical_skills"] = [];
    }

    // //console.log(convertedResume);
    return convertedResume;
  }
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (datachanged) {
        const confirmationMessage =
          "You have unsaved changes. Do you really want to leave?";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [datachanged]);

  useEffect(() => {
    const gettingData = async () => {
      if (profileProfessionalData) {
        let data = await convertData(profileProfessionalData);
        userPersonalData["Awards"] = data["awards"];
        userPersonalData["Certificates"] = data["certificates"];
        userPersonalData["Courses"] = data["courses"];
        userPersonalData["Declaration"] = data["declarations"];
        userPersonalData["Interests and hobbies"] = data["interests"];
        userPersonalData["Interpersonal Skills"] = data["interpersonal_skills"];
        userPersonalData["Languages"] = data["languages"];
        userPersonalData["Project Lists"] = data["projects"];
        userPersonalData["Publication"] = data["publications"];
        userPersonalData["Reference"] = data["references"];
        userPersonalData["Technical Skills"] = data["technical_skills"];

        if (data["education"] && data["education"].length > 0) {
          userPersonalData["College or University 1 with degree"] =
            data["education"][0];
        }
        if (data["education"] && data["education"].length > 1) {
          userPersonalData["College or University 2 with degree"] =
            data["education"][1];
        }
        if (data["education"] && data["education"].length > 2) {
          userPersonalData["College or University 3 with degree"] =
            data["education"][2];
        }

        if (data["work_experience"] && data["work_experience"].length > 0) {
          userPersonalData["Role 1 with Organization Name"] =
            data["work_experience"][0];
        }
        if (data["work_experience"] && data["work_experience"].length > 1) {
          userPersonalData["Role 2 with Organization Name"] =
            data["work_experience"][1];
        }
        if (data["work_experience"] && data["work_experience"].length > 2) {
          userPersonalData["Role 3 with Organization Name"] =
            data["work_experience"][2];
        }

        for (const key in data["links"]) {
          userPersonalData[key] = data["links"][key];
        }
        //console.log(userPersonalData);
        setUserPersonalData(userPersonalData);
      }
    };
    gettingData();
  }, [profileProfessionalData]);

  const saveData = async (apiUrl, key, bodykey) => {
    let dataArray = userPersonalData[key];
  
    // Ensure dataArray is an array or wrap it in an array if it's "NOT AVAILABLE" or a string
    if (dataArray === undefined || dataArray === "") {
      dataArray = [];
    } else if (typeof dataArray === "string") {
      dataArray = [dataArray];
    }
  
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id,
        [bodykey]: dataArray,
      }),
    };
  
    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
    } catch (error) {
      console.error(`Error for ${key}:`, error.message);
    }
  };

  const saveChanges = async () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);

    if (links !== undefined) {
      const jsonLinks = {};

      for (const link of links) {
        jsonLinks[link.title] = link.url;
      }

      const apiUrlskill = `${process.env.REACT_APP_API_URL}/save_links`;
      const requestOptionsskill = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          json_data: jsonLinks,
        }),
      };
      try {
        const response = await fetch(apiUrlskill, requestOptionsskill);
        const data = await response.json();
      } catch (error) {
        console.error("Error:", error.message);
      }
    }

    const dataItems = [
      {
        key: "Interests and hobbies",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_interests`,
        bodyText: "interest",
      },
      {
        key: "Certificates",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_certificates`,
        bodyText: "certificate",
      },
      {
        key: "Languages",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_languages`,
        bodyText: "language",
      },
      {
        key: "Awards",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_awards`,
        bodyText: "awards",
      },
      {
        key: "Declaration",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_declaration`,
        bodyText: "declaration",
      },
      {
        key: "Reference",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_reference`,
        bodyText: "reference",
      },
      {
        key: "Publication",
        apiUrl: `${process.env.REACT_APP_API_URL}/save_publications`,
        bodyText: "publication",
      },
    ];

    for (const item of dataItems) {
      await saveData(item.apiUrl, item.key, item.bodyText);
    }

    // Additional data key and corresponding API URL for Interpersonal Skills
    let skills = userPersonalData["Interpersonal Skills"];

    if (skills !== undefined) {
      //console.log("Skills are", skills);
      const InterpersonalSkills = skills;

      const apiUrlskill = `${process.env.REACT_APP_API_URL}/save_interpersonal_skills`;
      const requestOptionsskill = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          skill: InterpersonalSkills,
        }),
      };
      try {
        const response = await fetch(apiUrlskill, requestOptionsskill);
        const data = await response.json();
      } catch (error) {
        console.error("Error:", error.message);
      }
    }

    // // // Additional data key and corresponding API URL for Courses
    let g = userPersonalData["Courses"];

    if (g !== undefined) {
      const course_name = g;

      const apiUrlCourses = `${process.env.REACT_APP_API_URL}/save_courses`;
      const requestOptionsCourses = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          course_name,
          reference_link: "aa",
          course_description: "aa",
        }),
      };
      try {
        const response = await fetch(apiUrlCourses, requestOptionsCourses);
        const data = await response.json();

      } catch (error) {
        console.error("Error:", error.message);
      }
    }
    let h = userPersonalData["Technical Skills"];
    if (h !== undefined) {
      const skill = h;
      const apiUrlskill = `${process.env.REACT_APP_API_URL}/save_technical_skills`;
      const requestOptionsskill = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          skill,
        }),
      };
      try {
        const response = await fetch(apiUrlskill, requestOptionsskill);
        const data = await response.json();
      } catch (error) {
        console.error("Error:", error.message);
      }
    }

    let role1 = userPersonalData["Role 1 with Organization Name"];
    let role2 = userPersonalData["Role 2 with Organization Name"];
    let role3 = userPersonalData["Role 3 with Organization Name"];
    const companies = [];
    const positions = [];
    const startDates = [];
    const endDates = [];
    const descriptions = [];

    if (role1 !== undefined) {
      const { company, position, description, startDate, endDate } = role1;
      companies.push(company);
      positions.push(position);
      startDates.push(startDate);
      endDates.push(endDate);
      descriptions.push(description);
    }

    if (role2 !== undefined) {
      const { company, position, description, startDate, endDate } = role2;
      companies.push(company);
      positions.push(position);
      startDates.push(startDate);
      endDates.push(endDate);
      descriptions.push(description);
    }

    if (role3 !== undefined) {
      const { company, position, description, startDate, endDate } = role3;
      companies.push(company);
      positions.push(position);
      startDates.push(startDate);
      endDates.push(endDate);
      descriptions.push(description);
    }

    const apiUrlrole = `${process.env.REACT_APP_API_URL}/save_professional_experience`;
    const requestOptionsrole = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        experience_org: companies,
        experience_position: positions,
        experience_description: descriptions,
        exp_start_date: startDates,
        end_date: endDates,
        exp_uuid: [user_id],
      }),
    };
    try {
      const response = await fetch(apiUrlrole, requestOptionsrole);
      const data = await response.json();
      dispatch(fetchProfileProfessionalData(user_id));
    } catch (error) {
      console.error("Error:", error.message);
    }

    const degree1 = userPersonalData["College or University 1 with degree"];
    const degree2 = userPersonalData["College or University 2 with degree"];
    const degree3 = userPersonalData["College or University 3 with degree"];

    const institutions = [];
    const degrees = [];
    const scores = [];

    if (degree1 !== undefined) {
      const { institution, degree, score } = degree1;
      institutions.push(institution);
      degrees.push(degree);
      scores.push(score);
    }
    if (degree2 !== undefined) {
      const { institution, degree, score } = degree2;
      institutions.push(institution);
      degrees.push(degree);
      scores.push(score);
    }
    if (degree3 !== undefined) {
      const { institution, degree, score } = degree3;
      institutions.push(institution);
      degrees.push(degree);
      scores.push(score);
    }

    const apiUrldegree = `${process.env.REACT_APP_API_URL}/save_education_qualification`;
    const requestOptionsdegree = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        college_uni_name: institutions,
        degree: degrees,
        percent: scores,
      }),
    };
    try {
      const response = await fetch(apiUrldegree, requestOptionsdegree);
      const data = await response.json();
      //console.log("Response degree :", data);
      dispatch(fetchProfileProfessionalData(user_id));
    } catch (error) {
      console.error("Error:", error.message);
    }

    let proj = userPersonalData["Project Lists"];


if (proj !== undefined) {
  const apiUrlProject = `${process.env.REACT_APP_API_URL}/save_project`;

  // Find the length of the longest sub-array
  const maxSkillsLength = Math.max(...proj.map(project => project.technologiesUsed.length));

  // Pad sub-arrays to match lengths
  const paddedSkills = proj.map(project => {
    const skills = project.technologiesUsed || [];
    while (skills.length < maxSkillsLength) {
      skills.push("");  // Or some other default value
    }
    return skills;
  });

  const requestOptionsProject = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id,
      project_name: proj.map(project => project.projectName),
      project_description: proj.map(project => project.projectDescription || ""),
      skills_technology_used: paddedSkills,
      reference_link: proj.map(project => project.projectLink || ""),
    }),
  };

  try {
    const response = await fetch(apiUrlProject, requestOptionsProject);
    const data = await response.json();
  } catch (error) {
    console.error("Error:", error.message);
  }
}
dispatch(fetchProfileProfessionalData(user_id));
  };

  return (
    <>
      {isLoading && <AlertModal msg="loading" />}
      {success && <AlertModal msg="success" />}
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-end mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Profile"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Profile"} />
            
              <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                <div className="flex flex-col gap-[43px] justify-start  mt-2.5 w-[99%] md:w-full">
                  <ProfileHeader2 activeTab={"professional"} />

                  <ProfessionalExtractForm
                    onDataFromChild={(data) => {
                      handleDataFromChild(data);
                      setdataChanged(true);
                    }}
                    setUserPersonalData={setUserPersonalData}
                  />
                </div>
              </div>
              
              <WorkExperienceProfessional
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setWorkExperience={setWorkExperience}
                setUserPersonalData={setUserPersonalData}
              />
              <EducationProfessional
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setRootEducation={setEducation}
                setUserPersonalData={setUserPersonalData}
              />
              <SkillsProfessional
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setSkills={setSkills}
                setUserPersonalData={setUserPersonalData}
              />
              <IntrestProfessional
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                interests={interests}
                setInterests={setInterests}
                setUserPersonalData={setUserPersonalData}
              />
              <LanguageProfessional
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setLanguages={setLanguages}
                setUserPersonalData={setUserPersonalData}
              />
              <CertificatesProfessional
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setCertificates={setCertificates}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalProject
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setProjects={setProjects}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalCourse
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setCourses={setCourses}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalAwards
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setAwards={setAwards}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalOrganisation
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setOrganisations={setOrganisations}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalPublication
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setPublications={setPublications}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalSocialLinks
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setLinks={setLinks}
                setUserPersonalData={setUserPersonalData}
              />
              <ProfessionalReference
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setReferences={setReferences}
                setUserPersonalData={setUserPersonalData}
              />

              <ProfessionalDeclaration
                handleDataUpdate={handleDataUpdate}
                dataOfUser={userPersonalData}
                setDeclarations={setDeclarations}
                setUserPersonalData={setUserPersonalData}
              />

              <div className="flex flex-row justify-end w-[95%] mb-[20px]">
                {!datachanged ? (
                  <Button
                    style={{ border: "2px solid #314ca3" }}
                    className=" sm:text-[12px]  px-[3.4rem] py-[0.9rem] text-[1.2rem]  mqlap:px-[2.85vw] mqlap:rounded-[12px] mqtab1:rounded-[12px]   mqlap:py-[0.7vw] mqlap:text-[1vw] mqtab1:px-[2.9vw]  mqtab1:py-[0.7vw]  mqtab1:text-[1vw] bg-gray-100 text-indigo-600  border-indigo-600 cursor-pointer font-medium  leading-[normal] md:ml-0 text-center text-lg  flex flex-row justify-center items-center "
                    size="md"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    onClick={saveChanges}
                    type="submit"
                    className=" sm:text-[12px]  px-[3.4rem] py-[0.9rem] text-[1.2rem]  mqlap:px-[2.85vw] mqlap:rounded-[12px] mqtab1:rounded-[12px]   mqlap:py-[0.7vw] mqlap:text-[1vw] mqtab1:px-[2.9vw]  mqtab1:py-[0.7vw]  mqtab1:text-[1vw] bg-gray-100 text-indigo-600  border-indigo-600 cursor-pointer font-medium  leading-[normal] md:ml-0 text-center text-lg  flex flex-row justify-center items-center "
                    size="md"
                  >
                    Save
                  </Button>
                )}
              </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Professional;
