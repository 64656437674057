
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DCarousel from "../../../components/company_animation/App";
import "./index.css";
import SocialButtons from "./socialbuttons";
import ToggleButton from "./toggle";
import { Text } from "components";
const Desktop1520plusMadeOn1920 = () => {
  const navigate = useNavigate();

  const [currentSvg, setCurrentSvg] = useState(1);

  const svgImages = [
    "text_resume_builder.svg",
    "text_cover_letter.svg",
    "text_job_search.svg",
    "text_interview_prep.svg",
    "text_career_advisor.svg",
    "text_auto_job_apply.svg",
    "text_free_candidates_db.svg",
    "text_automated_assessments.svg",
    "text_auto_job_apply.svg",
    "text_auto_interview_scheduler.svg",
  ];

  const toggleSVG = () => {
    setCurrentSvg((prev) => (prev < 10 ? prev + 1 : 1));
  };

  useEffect(() => {
    const interval = setInterval(toggleSVG, 2000); // Change SVG every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);
  const [isInIndia, setIsInIndia] = useState(false);

  const isInIndiaCheck = (latitude, longitude) => {
    return (
      latitude >= 8.067 &&
      latitude <= 37.1 &&
      longitude >= 68.117 &&
      longitude <= 97.417
    );
  };

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            const inIndia = isInIndiaCheck(latitude, longitude);
            setIsInIndia(inIndia);
          },
          (error) => {
            setLocationError(error.message);
          }
        );
      } else {
        setLocationError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  // const onProductTagContainerClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='productJobSeekersContainer']",
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView();
  //   }
  // }, []);

  // const onPricingTagContainerClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='pricingContainer']",
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView();
  //   }
  // }, []);

  // const onAboutUsTagContainerClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='contentContainer']",
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView();
  //   }
  // }, []);

  const handleLogoClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='Header']");
    if (anchor) {
      anchor.scrollIntoView();
    }
  }, []);

  const onButtonContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const [revstate, SetrevState] = useState(0);

  const reviewname = ["Srinivas", "Shrashti", "Somesh", "Jatin"];

  const reviewcompany = [
    "HR Manger, Culinda Inc.",
    "Data Scientist, Culinda Inc.",
    "ML Engg. Culinda Inc.",
    "ML Intern, Magix DB",
  ];

  // const reviewnumber = ["1/4", "2/4", "3/4", "4/4"];

  const reviewcontent = [
    "We use Smart Recruiter for hiring for all positions in our company.️ Smart recruiter has cut down our cost for recruiting team. We don't need a recruiting team to schedule interviews and follow ups. We are able to hire people 10X faster.",
    "RecRoid revolutionized our hiring process, cutting costs and accelerating recruitment by 10x. No need for a dedicated recruiting team; it's all automated and streamlined.",
    "RecRoid is a game-changer. With its advanced automation, we've not only cut recruitment costs significantly but also managed to hire top-tier talent in record time. No more manual scheduling or follow-ups; the platform handles it all seamlessly. It's a win-win for our organization.",
    "RecRoid is a must-have for efficient hiring. Our hiring speed improved by an impressive 10x, and we've seen a remarkable reduction in hiring costs. Our hiring process is not just faster but also more cost-effective, making it an indispensable tool for any organization.",
  ];

  return (
    <div className="desktop-1520plus-made-on-1920">
      <div className="new-landing-page-desktop-192">
        <div className="new-landing-page-desktop-192">
          <div className="landing-page-desktop-19201">
            <div className="website-header">
              <div data-scroll-to="Header" className="header">
                <div className=" text-xl font-medium right-fields flex flex-row justify-between ">
                  <div
                    className="overlay w-[26px] h-[25px]   absolute top-[-4px] left-[-22px] z-20 transform rotate-[-78deg]"
                    style={{ backgroundColor: "white" }}
                  ></div>
                  <img
                    src="/comingSoon4.png"
                    alt=""
                    className=" transform rotate-0 w-[115px] h-[39px]  absolute  top-[1.5px] left-[-17px] z-10"
                  />

                  <Link to="/recruiter-login" className="company">
                    <div
                      //  className="job-seeker "
                      style={{
                        border: "2px solid #314ca3",
                        color: "#314ca3",
                        padding: 12,
                        // left: -37,
                        paddingLeft: 60,
                      }}
                      className="job-seekers landingpage  hover:bg-gray-50  rounded-[8px]  "
                    >
                      Employer
                    </div>
                  </Link>
                  <div
                    className="job-seeker rounded-[8px]"
                    onClick={onButtonContainerClick}
                    style={{ border: "2px solid #314ca3", padding: 14 }}
                  >
                    <div
                      style={{ color: "#314ca3" }}
                      className="job-seekers landingpage"
                    >
                      Job seekers
                    </div>
                  </div>
                </div>

                <div
                  // style={{backgroundColor:"red"}}
                  //  style={{ fontSize: "larger", fontWeight: "500" }}
                  className="left-fields text-xl font-medium"
                >
                  <div
                    className="product-tag"
                    onClick={() => {
                      window.scrollTo(0, 950);
                    }}
                  >
                    <div
                      style={{ color: "#314ca3" }}
                      className="product landingpage"
                    >
                      Products
                    </div>
                  </div>
                  <div
                    className="pricing-tag"
                    onClick={() => {
                      window.scrollTo(0, 4400);
                    }}
                  >
                    <div
                      style={{ color: "#314ca3" }}
                      className="pricing landingpage"
                    >
                      Pricing
                    </div>
                  </div>
                    <div className="about-us-tag">
                    <a href="/about-us">
                      <div
                        style={{ color: "#314ca3" }}
                        className="product landingpage"
                      >
                        About us
                      </div>
                  </a>
                    </div>
                </div>

                <div
                  onClick={handleLogoClick}
                  className="website-name-frame-parent"
                >
                  <div className=" cursor-pointer website-name-frame-op2">
                    <div
                      style={{ color: "rgb(49 76 163)" }}
                      className="website-name"
                    >
                      RecRoid
                    </div>
                  </div>
                  <div className="logo-frame">
                    <img
                      className=" cursor-pointer recruiter-robo-logo-3-1-icon-op2"
                      alt=""
                      src="/recroid.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="website-main">
              <div className="whyus">
                <div className="content-container">
                  <div className="subheading-container-parent">
                    <div className="subheading-container">
                      <b className="job-seekers1">JOB SEEKERS</b>
                      <b className="recruiters">RECRUITERS</b>
                    </div>
                    <div className="content-rows-container">
                      <div className="job-seeker-column">
                        <div className="st-advantage-container">
                          <div className="text-container">
                            <div className="our-all-the">
                              Our all the clients vacancy undisclosed in the
                              market is available to you.
                            </div>
                            <b className="heading-6">
                              Access to undisclosed Jobs
                            </b>
                          </div>
                        </div>
                        <div className="nd-advantage-container">
                          <div className="text-container1">
                            <div className="everything-you-require">
                              Everything you require to get you hire is here at
                              one platform
                            </div>
                            <b className="heading-61">
                              Everything to get you hired
                            </b>
                          </div>
                        </div>
                      </div>
                      <div className="recruiter-column">
                        <div className="st-advantage-container1">
                          <div className="text-container2">
                            <b className="heading-62">Simple Fixed Pricing</b>
                            <div className="no-waiting-for">{`No waiting for quotes from the company. `}</div>
                          </div>
                        </div>
                        <div className="nd-advantage-container1">
                          <div className="text-container3">
                            <b className="heading-63">
                              Candidates Database at no cost
                            </b>
                            <div className="in-house-candidates">
                              {" "}
                              In house Candidates database aviable at no extra
                              cost
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="heading-container">
                    <div className="yellow-text" />
                    <b className="heading-2">Everything at One Place</b>
                  </div>
                </div>
                <div className="App">
                  <img
                    src="images/yellow_ball.svg"
                    alt="Ball"
                    className="yball"
                  />
                </div>
              </div>
              <div className="resumetemplates">
                <div className="content-container1">
                  <div className="resumetemplates1">
                    <div className="resume-1">
                      <img
                        className="image-1-icon"
                        alt=""
                        src="/image-1@2x.png"
                      />
                      <div className="resume-1-child" />
                    </div>
                    <div className="resume-2">
                      <img
                        className="image-2-icon"
                        alt=""
                        src="/image-2@2x.png"
                      />
                      <div className="resume-2-child" />
                    </div>
                    <div className="resume-3">
                      <div className="resume-3-child" />
                      <img
                        className="image-3-icon"
                        alt=""
                        src="/image-3@2x.png"
                      />
                    </div>
                  </div>
                  <Link to="/all-resumetemplates" className="moretemplates">
                    <div className="text-container4">
                      <div className="take-me-to">More Templates</div>
                    </div>
                    <img
                      className="arrow-container-icon"
                      alt=""
                      src="/arrow-container.svg"
                    />
                  </Link>
                </div>
                <div className="title-conatiner">
                  <b className="partners">Resume Templates</b>
                </div>
                <div className="ball-container">
                  <img
                    className="ball1svg-icon"
                    alt=""
                    src="/ball1svg@2x.png"
                  />
                </div>
              </div>
              <div
                className="autoapply"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="heading-container1">
                  <div className="text-container5">
                    <b className="auto-apply">Auto Apply</b>
                  </div>
                  <div className="yellow-badge">
                    <b className="partners">partners</b>
                  </div>
                </div>
                <DCarousel />
              </div>
              <div className="smartjobseekersdemo">
                <div className="video-container">
                  <img
                    className="video-recruiter-demo-icon"
                    alt=""
                    src="/video-recruiter-demo@2x.png"
                  />
                </div>
                <div
                  className="button-container"
                  onClick={onButtonContainerClick}
                >
                  <div className="button-text-container">
                    <div className="take-me-to1">To Smart Job Seeker</div>
                  </div>
                  <img
                    className="arrow-container-icon1"
                    alt=""
                    src="/arrow-container1.svg"
                  />
                </div>
                <div className="yellow-ball-image-container">
                  <img
                    className="ball2svg-icon"
                    alt=""
                    src="/ball2svg@2x.png"
                  />
                </div>
                <div className="green-ball-image-container">
                  <img
                    className="ball3svg-icon"
                    alt=""
                    src="/ball3svg@2x.png"
                  />
                </div>
                <div className="heading-container2">
                  <b className="heading-22">Smart Job Seeker Demo</b>
                </div>
              </div>
              <div className="recruiterdemo">
                <div className="image-background-container">
                  <img
                    className="bg-ractangle-shapepng-icon"
                    alt=""
                    src="/bgractangleshapepng@2x.png"
                  />
                  <div className="content-container2">
                    <div className="video-container1">
                      <img
                        className="video-recruiter-demo-icon1"
                        alt=""
                        src="/video-recruiter-demo1@2x.png"
                      />
                    </div>
                    <div className="content-container3">
                      <div className="watch-this-demo">
                        Watch this demo to see how easily the Smart Recruiter
                        integrates with you Mail account and starts automating
                        you hiring pipeline.
                      </div>
                      <b className="heading-23">Smart Recruiter Demo</b>
                      {/* <Link
                        to="/recruiter-login"
                        className="button-container1 cursor-pointer"
                      > */}
                      <div className="button-container1 ">
                        <div className="button-text-container1  ">
                          <div className="take-me-to2 text-[16px]">Coming Soon</div>
                        </div>
                        <img
                          className="arrow-container-icon2"
                          alt=""
                          src="/arrow-container2.svg"
                        />
                        </div>
                      {/* </Link> */}
                    </div>
                  </div>
                  <div className="ball-container1">
                    <img
                      className="ball3svg-icon"
                      alt=""
                      src="/ball3svg1@2x.png"
                    />
                  </div>
                </div>
              </div>
              <div className="happy-customers">
                <div className="customers-stats-container">
                  <div className="content-container4">
                    <div className="countries-container">
                      <b className="heading-24">200</b>
                      <div className="countries-worldwide">
                        Countries Worldwide
                      </div>
                    </div>
                    <div className="users-container">
                      <div className="heading-25">
                        <b className="k">156K</b>
                      </div>
                      <div className="registered-user">Registered User</div>
                    </div>
                    <div className="companies-container">
                      <div className="heading-26">
                        <b className="k1">23K</b>
                      </div>
                      <div className="small-big">{`Small & Big Companies`}</div>
                    </div>
                  </div>
                </div>
                <div className="rectangle-image-container">
                  <img
                    className="bg-ractangle-shapepng-icon1"
                    alt=""
                    src="/bgractangleshapepng1@2x.png"
                  />
                  <div className="ball-container2">
                    <img
                      className="ball2svg-icon"
                      alt=""
                      src="/ball2svg1@2x.png"
                    />
                  </div>
                  <div className="content-container5">
                    <div className="divrow">
                      <div className="divhappy-customer-left">
                        <div className="arrow-left-blacksvg" />
                        <div className="divcustomers-name">
                          <div className="heading-64">
                            {reviewname[revstate]}
                          </div>
                          <b className="heading-5">{reviewcompany[revstate]}</b>
                          <div className="div">{revstate + 1}/4</div>
                          <img
                            className="arrow-right-blacksvg-icon cursor-pointer"
                            alt=""
                            src="/arrowrightblacksvg.svg"
                            onClick={() =>
                              revstate === 3 ? "" : SetrevState(revstate + 1)
                            }
                          />
                          <img
                            className="arrow-left-blacksvg-icon"
                            alt=""
                            src="/arrowleftblacksvg.svg"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              revstate === 0 ? "" : SetrevState(revstate - 1)
                            }
                          />
                        </div>
                        <div className="p">
                          <div className="we-use-smart">
                            {reviewcontent[revstate]}
                          </div>
                        </div>
                      </div>
                      <div className="divhappy-customer-right">
                        <div className="emojisvg">
                          <img
                            className="emojisvg-icon"
                            alt=""
                            src="/emojisvg.svg"
                          />
                        </div>
                        <div className="divslick-list">
                          <div className="divslick-track">
                            <div className="divslider-img">
                              <div className="pseudo" />
                              <img
                                className="happy-customers1png-icon"
                                alt=""
                                src="/happycustomers1png@2x.png"
                              />
                            </div>
                            <div className="divslider-img1">
                              <div className="divhalfclip">
                                <div className="divhalfcircle" />
                              </div>
                              <div className="pseudo" />
                              <img
                                className="happy-customers1png-icon"
                                alt=""
                                src="/happycustomers2png@2x.png"
                              />
                            </div>
                            <div className="divslider-img2">
                              <div className="pseudo" />
                              <img
                                className="happy-customers1png-icon"
                                alt=""
                                src="/happycustomers3png@2x.png"
                              />
                            </div>
                            <div className="divslider-img3">
                              <div className="pseudo" />
                              <img
                                className="happy-customers1png-icon"
                                alt=""
                                src="/happycustomers4png@2x.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divhappy-customers-title">
                      <b className="heading-27">Happy customers</b>
                      <div className="heading-51">
                        Worldwide 30,000+ customer
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing1" data-scroll-to="pricingContainer">
                <div className="ball1svg">
                  <img
                    className="ball1svg-icon"
                    alt=""
                    src="/ball1svg1@2x.png"
                  />
                </div>
                <div className="card-offers">
                  <div className="list">
                    <div className="item">
                      <img className="img-icon" alt="" src="/img.svg" />
                      <div className="no-cards-required">No cards required</div>
                    </div>
                    <div className="item1">
                      <img className="img-icon" alt="" src="/img.svg" />
                      <div className="no-cards-required">7 day free trial</div>
                    </div>
                    <div className="item2">
                      <img className="img-icon" alt="" src="/img.svg" />
                      <div className="no-cards-required">
                        Get started in 2 minutes
                      </div>
                    </div>
                  </div>
                </div>
                <div className="price-card-container">
                  <div
                    className="jobseeker-price-card"
                    
                  >
                    <div className="button" onClick={onButtonContainerClick}>
                      <b className="start-for-free">
                        {isToggled ? "Purchase Now" : "Start for free"}
                      </b>
                    </div>
                    <div className="list1">
                      <div className="item-unlimited">
                        Unlimited Resumes Builders
                      </div>
                      <div className="item-unlimited1">
                        Unlimited Resume Enhancements
                      </div>
                      <div className="item-unlimited2">
                        Unlimited Cover Letters
                      </div>
                      <div className="item-unlimited3">
                        Unlimited automation templates
                      </div>
                      <div className="item-200">
                        200 min of Interview Preparation
                      </div>
                      <div className="item-unlimited4">
                        Unlimited Job Searches
                      </div>
                      <div className="item-unlimited5">
                        Unlimited Auto Job Apply
                      </div>
                      <div className="item-unlimited6">
                        Access to undisclosed Jobs
                      </div>
                    </div>
                    <div className="icons">
                      <div className="compare-price-shapesvg">
                        <img
                          className="compare-price-shapesvg-icon"
                          alt=""
                          src="/comparepriceshapesvg.svg"
                        />
                      </div>
                      <div className="crownsvg">
                        <img
                          className="crownsvg-icon"
                          alt=""
                          src="/crownsvg.svg"
                        />
                      </div>
                    </div>
                    <div className="heading-28">
                      <b className="strong-59">
                        {isInIndia ? (
                          isToggled ? (
                            <div>
                              <Text> ₹ 7655 per year</Text>

                              <Text className="font-medium text-lg">
                                {" "}
                                (₹ 510 per month)
                              </Text>
                            </div>
                          ) : (
                            "₹ 799 per month"
                          )
                        ) : isToggled ? (
                          <div>
                            <Text> $ 96 per year</Text>

                            <Text className="font-medium text-lg">
                              {" "}
                              ($ 8 per month)
                            </Text>
                          </div>
                        ) : (
                          "$ 9.99 per month"
                        )}
                      </b>
                    </div>
                    <b className="heading-52">For Job Seekers</b>
                  </div>
                  <div className="recruiter-price-card ">
                    <div className="button cursor-auto">
                      <b className="start-for-free">Coming Soon</b>
                    </div>

                    <div className="list2">
                      <div className="item-email">
                        Unlimited Resume Processing
                      </div>
                      <div className="item-unlimited7">
                        Unlimited Auto Interview Schedules
                      </div>
                      <div className="item-unlimited8">
                        Free Centralised Databases
                      </div>
                      <div className="item-unlimited9">
                        Access to 5000 free candidates profiles
                      </div>
                      <div className="item-great">Great Customer Support</div>
                      <div className="item-unlimited10">
                        Unlimited job Uploads and Shortlisting
                      </div>
                    </div>
                    <div className="icons1">
                      <div className="compare-price-shapesvg1">
                        <img
                          className="compare-price-shapesvg-icon"
                          alt=""
                          src="/comparepriceshapesvg.svg"
                        />
                      </div>
                      <div className="crown-2svg">
                        <img
                          className="crown-2svg-icon"
                          alt=""
                          src="/crown2svg.svg"
                        />
                      </div>
                    </div>
                    <div className="heading-29  ">
                      <b className="strong-59">
                        {isInIndia ? (
                          isToggled ? (
                            <div>
                              <Text>₹ 47280 per year</Text>

                              <Text className="font-medium text-lg">
                                {" "}
                                (₹ 3940 per month)
                              </Text>
                            </div>
                          ) : (
                            "₹ 4920 per month"
                          )
                        ) : isToggled ? (
                          <div>
                            <Text>$ 566 per year</Text>

                            <Text className="font-medium text-lg">
                              {" "}
                              ($ 47.20 per month)
                            </Text>
                          </div>
                        ) : (
                          // <>
                          //   $ 566 per year
                          //   <br />
                          //   ($ 47.20 per month)
                          // </>
                          "$ 59 per month"
                        )}
                      </b>
                      {/* <div className="superscript">$</div> */}
                    </div>
                    <b className="heading-53 ">For Recruiters</b>
                  </div>
                  <div className=" university-price-card  ">
                    <div className="button cursor-auto">
                      <b className="start-for-free">Coming Soon</b>
                    </div>
                    <div className="list2">
                      <div className="item-email">
                        Unlimited Resume Processing
                      </div>
                      <div className="item-unlimited7">
                        Unlimited Auto Interview Schedules
                      </div>
                      <div className="item-unlimited8">
                        Free Centralised Databases
                      </div>
                      <div className="item-unlimited9">
                        Access to 5000 free candidates profiles
                      </div>
                      <div className="item-great">Great Customer Support</div>
                      <div className="item-unlimited10">
                        Unlimited job Uploads and Shortlisting
                      </div>
                    </div>
                    <div className="icons1">
                      <div className="compare-price-shapesvg1">
                        <img
                          className="compare-price-shapesvg-icon"
                          alt=""
                          src="/comparepriceshapesvg.svg"
                        />
                      </div>
                      <div className="crown-2svg">
                        <img
                          className="crown-2svg-icon"
                          alt=""
                          src="/crown2svg.svg"
                        />
                      </div>
                    </div>
                    <div className="heading-29">
                      <b className="strong-59">
                        {isInIndia ? (
                          isToggled ? (
                            <div>
                              <Text> ₹ 47280 per year</Text>

                              <Text className="font-medium text-lg">
                                {" "}
                                (₹ 3940 per month)
                              </Text>
                            </div>
                          ) : (
                            "₹ 4920 per month"
                          )
                        ) : isToggled ? (
                          <div>
                            <Text> $ 566 per year</Text>

                            <Text className="font-medium text-lg">
                              {" "}
                              ($ 47.20 per month)
                            </Text>
                          </div>
                        ) : (
                          "$ 59 per month"
                        )}
                      </b>
                      {/* <div className="superscript">$</div> */}
                    </div>
                    <b className="heading-53">For University</b>
                  </div>
                </div>
                <div className="yearly-payment-tab">
                  <div className="toggle-btn" style={{ fontSize: "30px" }}>
                    <span>Monthly</span>
                    <div onClick={handleToggle}>
                      <ToggleButton />
                    </div>
                    <span>
                      Yearly
                      <span style={{ color: "#00CEC9" }}>(20% Off)</span>
                    </span>
                  </div>
                </div>
                <div className="heading-container3">
                  <div className="top-heading-container">
                    <b className="pricing-table">Pricing Table</b>
                  </div>
                  <div className="heading1-container">
                    <b className="giving-you-good">Giving you good value</b>
                  </div>
                  <div className="heading2-container">
                    <b className="with">{`with `}</b>
                  </div>
                  <div className="yellow-badge1">
                    <b className="best-service">best service</b>
                  </div>
                </div>
              </div>
              <div className="product-recruiter">
                <div className="ball2svg">
                  <img
                    className="ball2svg-icon"
                    alt=""
                    src="/ball2svg2@2x.png"
                  />
                </div>
                <div className="divdividing-line" />
                <div className="content-container6">
                  <div className="title-container">
                    <b className="product1">PRODUCTS</b>
                    <b className="heading-210">For Recruiters</b>
                  </div>
                  <div className="product-recruiters-content">
                    <div className="integrate-emails-row">
                      <div className="image-container">
                        <img
                          className="integrate-emails-container-icon"
                          alt=""
                          src="/integrate-emails-container@2x.png"
                        />
                      </div>
                      <div className="divpowerfull-template-number">
                        <b className="b">1</b>
                      </div>
                      <div className="integrate-emails-content-conta">
                        <b className="heading-4">Integrate Emails</b>
                        <div className="sub-content">
                          <div className="integrate-outlook-g">
                            {" "}
                            Integrate Outlook, G suite or any other professional
                            email box where you receive candidates profiles.
                          </div>
                        </div>
                        <div className="list3">
                          <div className="item3">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              All major professionals email Integration
                              Available
                            </div>
                          </div>
                          <div className="item4">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Automated Profile identification and merging to
                              database
                            </div>
                          </div>
                          <div className="item5">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Real Time Profile processing
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="centralised-database">
                      <div className="content-container7">
                        <div className="heading-65">
                          <b className="heading-41">Centralized Database</b>
                        </div>
                        <div className="subcontent">
                          <div className="maintain-candidates-resumes">
                            Maintain candidates resumes, cover letter, their
                            performances on assignments and interviews..
                          </div>
                        </div>
                        <div className="ordered-list">
                          <div className="item6">
                            <div className="monitor-iconsvg">
                              <img
                                className="crown-2svg-icon"
                                alt=""
                                src="/monitor-iconsvg.svg"
                              />
                            </div>
                            <div className="all-candidates-profiles">
                              All Candidates Profiles at one place
                            </div>
                          </div>
                          <div className="item7">
                            <div className="monitor-iconsvg">
                              <img
                                className="crown-2svg-icon"
                                alt=""
                                src="/message-iconsvg.svg"
                              />
                            </div>
                            <div className="all-candidates-profiles">
                              On boarding of HRs and Interviewers
                            </div>
                          </div>
                          <div className="item8">
                            <div className="monitor-iconsvg">
                              <img
                                className="crown-2svg-icon"
                                alt=""
                                src="/document-iconsvg.svg"
                              />
                            </div>
                            <div className="centralized-job-vacancies">
                              Centralized Job Vacancies
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="divpowerfull-template-number1">
                        <b className="b">2</b>
                      </div>
                      <div className="image-container1">
                        <img
                          className="centralised-database-image-icon"
                          alt=""
                          src="/centralised-database-image@2x.png"
                        />
                      </div>
                    </div>
                    <div className="send-assessments">
                      <div className="image-container2">
                        <img
                          className="sends-assesment-image-icon"
                          alt=""
                          src="/sends-assesment-image@2x.png"
                        />
                      </div>
                      <div className="divpowerfull-template-number">
                        <b className="b">3</b>
                      </div>
                      <div className="content-container8">
                        <b className="heading-42">
                          Sends automated Assessments
                        </b>
                        <div className="p1">
                          <div className="once-the-profile">
                            Once the profile is shortlisted the smart Recruiter
                            send an assessment to the candidate for the 1st
                            round of test.
                          </div>
                        </div>
                        <div className="list4">
                          <div className="item3">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Assessments on Leetcode, Hacker Rank
                            </div>
                          </div>
                          <div className="item10">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Plugins for custom exams
                            </div>
                          </div>
                          <div className="item5">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Real time results
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shortlist-profiles">
                      <div className="content-container9">
                        <b className="heading-43">Shortlist Profiles</b>
                        <div className="p2">
                          <div className="profiles-are-shortlisted">
                            Profiles are shortlisted based on the performance of
                            the candidate on the assessment. Flexibility to set
                            the exam cutoff
                          </div>
                        </div>
                        <div className="list5">
                          <div className="item3">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Profiles are shortlisted by the smart recruiter
                            </div>
                          </div>
                          <div className="item13">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              No human interaction required
                            </div>
                          </div>
                          <div className="item5">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">No biases</div>
                          </div>
                        </div>
                      </div>
                      <div className="divpowerfull-template-number1">
                        <b className="b">4</b>
                      </div>
                      <div className="image-container3">
                        <img
                          className="shortlist-profiles-image-icon"
                          alt=""
                          src="/shortlist-profiles-image@2x.png"
                        />
                      </div>
                    </div>
                    <div className="schedule-interviews">
                      <div className="image-container4">
                        <img
                          className="schedule-interview-image-icon"
                          alt=""
                          src="/schedule-interview-image@2x.png"
                        />
                      </div>
                      <div className="divpowerfull-template-number">
                        <b className="b">5</b>
                      </div>
                      <div className="content-container10">
                        <b className="heading-44">Schedule Interviews</b>
                        <div className="heading-66">
                          <div className="interviewrs-profiles-can">
                            Interviewrs profiles can be added and in tervieww
                            interviews are scheduled through mails
                            automatically, after matching the avaialble slots of
                            the interviewer and the candidate
                          </div>
                        </div>
                        <div className="ordered-list1">
                          <div className="item15">
                            <div className="monitor-iconsvg">
                              <img
                                className="crown-2svg-icon"
                                alt=""
                                src="/monitor-iconsvg.svg"
                              />
                            </div>
                            <div className="auto-interview-slot">
                              Auto Interview Slot matching
                            </div>
                          </div>
                          <div className="item7">
                            <div className="monitor-iconsvg">
                              <img
                                className="crown-2svg-icon"
                                alt=""
                                src="/message-iconsvg.svg"
                              />
                            </div>
                            <div className="auto-emails-for">{`Auto Emails  for scheduling interviews `}</div>
                          </div>
                          <div className="item8">
                            <div className="monitor-iconsvg">
                              <img
                                className="crown-2svg-icon"
                                alt=""
                                src="/document-iconsvg.svg"
                              />
                            </div>
                            <div className="reminders-before-interviews">
                              Reminders before interviews
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="send-invitation">
                      <div className="content-container11">
                        <b className="heading-45">Send Out Results</b>
                        <div className="p3">
                          <div className="after-the-feedback">{`After the feedback from the interviewer the feedback is mailed to the candidates. `}</div>
                        </div>
                        <div className="list6">
                          <div className="item3">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">{`Keeps your acceptance & rejection communication crisp`}</div>
                          </div>
                          <div className="item10">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              Good company repo
                            </div>
                          </div>
                          <div className="item5">
                            <img className="img-icon" alt="" src="/img1.svg" />
                            <div className="no-cards-required">
                              10X Faster hiring
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="divpowerfull-template-number1">
                        <b className="b">6</b>
                      </div>
                      <div className="image-container5">
                        <img
                          className="send-results-image-icon"
                          alt=""
                          src="/send-results-image@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="product-job-seekers"
                data-scroll-to="productJobSeekersContainer"
              >
                <div className="job-seeker-product-second-row-parent">
                  <div className="job-seeker-product-second-row">
                    <div className="interview-preparation">
                      <div className="text-container7">
                        <b className="heading-54">Interview Preparation</b>
                        <div className="prepare-for-live">{`Prepare for live interviews for jobs and academics and get a detailed analysis on your performance of you content, language & body language.`}</div>
                      </div>
                      <div className="image-container6">
                        <img
                          className="interview-prep-image-icon"
                          alt=""
                          src="/interview-prep-image@2x.png"
                        />
                      </div>
                    </div>
                    <div className="career-advise">
                      <div className="text-container8">
                        <b className="heading-55">Career Advise</b>
                        <div className="get-career-advise">{`Get career advise with jobs, research & colleges lists along with salary, stipend and expenses from our highly curated database with an AI powered counselor.`}</div>
                      </div>
                      <div className="image-container7">
                        <img
                          className="career-advise-image-icon"
                          alt=""
                          src="/career-advise-image@2x.png"
                        />
                      </div>
                    </div>
                    <div className="auto-apply1">
                      <div className="text-conatiner">
                        <b className="heading-56">Auto Job Apply</b>
                        <div className="relax-while-our">
                          Relax, while our bots apply for relevant jobs for you
                          to our 20+ auto jobs partners
                        </div>
                      </div>
                      <div className="image-container8">
                        <img
                          className="autojob-apply-image-icon"
                          alt=""
                          src="/autojob-apply-image@2x.png"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="job-seeker-product-first-row">
                    <div className="build-and-enhance-resume">
                      <div className="text-container9">
                        <b className="heading-57">{`Build & Enahnce Resume`}</b>
                        <div className="prepare-for-live">
                          Create a personalized resume on a professional
                          templates. Build from scratch or various sources.
                          Enahnce Resume using AI
                        </div>
                      </div>
                      <div className="image-container9">
                        <img
                          className="resume-builder-image-icon"
                          alt=""
                          src="/resume-builder-image@2x.png"
                        />
                      </div>
                    </div>

                    <div className="build-cover-letters">
                      <div className="text-container10">
                        <b className="heading-58">Build Cover Letter</b>
                        <div className="craft-compelling-cover">
                          Craft compelling cover letter with our AI powered
                          technology
                        </div>
                      </div>
                      <div className="image-container10">
                        <img
                          className="cover-letter-image-icon"
                          alt=""
                          src="/cover-letter-image@2x.png"
                        />
                      </div>
                    </div>

                    <div className="job-search">
                      <div className="text-conatiner1">
                        <b className="heading-59">Job Search</b>
                        <div className="prepare-for-live">
                          Look for jobs on 50 plus platforms and apply directly.
                          Get access to undisclosed jobs in market too.
                        </div>
                      </div>
                      <div className="image-container11">
                        <img
                          className="job-search-image-icon"
                          alt=""
                          src="/job-search-image@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="title-conatiner1">
                  <b className="products">PRODUCTS</b>
                  <b className="heading-211">For Job Seekers</b>
                </div>
                <div className="App">
                  <img src="images/ball3.svg" alt="Ball" className="ball" />
                </div>
              </div>
              <div className="opening-page">
                <div className="image-container-2">
                  <img
                    className="opening-page-gif-icon"
                    alt=""
                    src="Opening_page_gif.gif"
                  />
                </div>
                <div className="text-container-parent">
                  <div className="text-container11">
                    <b className="democratizing-recruitment">
                      Democratizing Recruitment
                    </b>
                  </div>
                  <div className="text-animation">
                    <div className="svg-container">
                      <img
                        className="animated-svg"
                        src={`images/${svgImages[currentSvg - 1]}`}
                        alt="resume"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="website-footer">
              <div
                className="content-container12"
                data-scroll-to="contentContainer"
              >
                <div className="all-rights-reserved-parent">
                  <div className="all-rights-reserved">
                    <div className="all-rights-reserved1">
                      2024. All rights reserved.
                    </div>
                  </div>
                  <div className="products-parent">
                    <div className="products1">
                      <div className="heading-67">Products</div>
                      <div
                        className="item-link3 cursor-pointer  "
                        onClick={() => {
                          window.scrollTo(0, 6800);
                        }}
                      >
                        Smart Recruiter
                      </div>
                      <div
                        className="item-link3 cursor-pointer "
                        onClick={() => {
                          window.scrollTo(0, 7700);
                        }}
                      >
                        Smart JobSeeker
                      </div>
                      <a
                        href="/book-demo"
                        className="no-underline text-[#454545]"
                      >
                        <div className="item-link3">Book a Demo</div>
                      </a>
                    </div>
                    <div className="heading-6-products-parent">
                      <div className="heading-68">Company</div>
                      <a href="/about-us">
                        <div className="item-link7">About</div>
                      </a>
                      <a href="/policies">
                        <div className="item-link7">Policy</div>
                      </a>
                      <a
                        href="/contact-us"
                        to="/contact-us"
                        className="no-underline text-[#454545]"
                      >
                        <div className="item-link7">Contact Us</div>
                      </a>
                      <a href="/careers">
                        <div className="item-link7">Careers</div>
                      </a>
                      <a href="/terms-of-use">
                        <div className="item-link7">Terms Of Use</div>
                      </a>
                    </div>
                  </div>
                  <div className="follow-us">
                    <div className="follow">Follow:</div>
                    <div className="list8">
                      <SocialButtons />
                    </div>
                  </div>
                  <div className="logo-container-parent">
                    <div className="logo-container">
                      <img
                        onClick={handleLogoClick}
                        className="cursor-pointer recruiter-robo-logo-3-2-icon"
                        alt=""
                        src="/recroid.png"
                      />
                    </div>
                    <div className="website-info-content">
                      <div className="recruiter-robo-is-container">
                        <p className="is-a-dedicated">
                          RecRoid is a dedicated platform for all recruitment
                          needs of Recruiters as well as job seekers. It uses
                          state of the art AI Models, various Integrations & RPA
                          to fulfill the requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="green-ball-image-container1">
                <img className="ball3svg-icon" alt="" src="/ball3svg3@2x.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop1520plusMadeOn1920;
