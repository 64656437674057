import { Pagination } from '@mui/material';

const InterviewPagination = ({ currentPage, totalItems, itemsPerPage, handlePageChange }) => {
    return (
        <div className="w-full flex flex-row justify-center">
            <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                className="flex flex-row justify-center items-center pt-[27px]"
                page={currentPage}
                count={Math.ceil(totalItems / itemsPerPage)}
                onChange={handlePageChange}

            />
        </div>
    );
};

export default InterviewPagination;
