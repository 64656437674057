import { setAccountType } from "actions/AccountType";
import { getPaymentIntents } from "actions/PaymentIntent";
import { fetchSubscriptionData } from "actions/SubscriptionData";
import { Button, Text } from "components";
import { ArrowBigRight } from "lucide-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UnPaidUser = () => {
    const [selectedPlan, setSelectedPlan] = useState("trial");
    const navigate = useNavigate();
    const user_id = useSelector(
        (state) => state.profilePersonalData?.personalData?.id,
    );
    const dispatch = useDispatch()
    const handlePlanSelection = (plan) => {
        setSelectedPlan(plan);
    };

    const handleTrialUser = async () => {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}/start-trial`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({ user_id }),
                }
            );
    
            if (response.ok) {
                // Display success message
                toast.info("Enjoy your trial subscription!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                // Update the state or UI to reflect the subscription status
                // Consider not using window.location.reload(), instead update state or context
            } else  {
                // Specific handling for 400 status code
                toast.error("You can only use the trial subscription once.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } 
            dispatch(setAccountType(user_id))
            dispatch(fetchSubscriptionData(user_id))
            dispatch(getPaymentIntents(user_id))
        } catch (error) {
            // Handle network errors or unexpected issues
            toast.error(`Network error: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    const handleCheckout = () => {
        if (!selectedPlan) {
            toast.error("Please select a plan before proceeding.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }

        if (selectedPlan === "trial") {
            handleTrialUser();
        } else {
            // Navigate to checkout for other plans
            navigate(`/checkout?subscriptionType=${selectedPlan}`);
        }
    };

    const benefits = [
        { title: "Comprehensive Resume Building", description: "Multiple resume templates and AI tools to help you create standout resumes." },
        { title: "Cover Letter Creation", description: "A variety of templates and AI assistance to craft impactful cover letters." },
        { title: "Curated Job Listings", description: "Job opportunities selected based on your preferences for roles, locations, and salaries." },
        { title: "Automated Job Applications", description: "We apply to jobs on your behalf across your chosen platforms, saving you time and effort." },
        { title: "Interview Preparation", description: "Mock interview sessions tailored to your preferred roles, ensuring you are well-prepared." },
        { title: "Career Advice", description: "Personalized career advice based on your academic achievements and career history." },
    ];

    const plans = [
        { id: 'trial', name: "Trial Plan", price: "₹ 0", period: "per month", description: "Use all benefits for up to 15 days." },
        { id: 'monthly', name: "Monthly Plan", price: "₹ 799", period: "per month", description: "Access all benefits monthly." },
        { id: 'yearly', name: "Yearly Plan", price: "₹ 7655", period: "per year", description: "Access all benefits yearly." }
    ];

    return (
        <div className="w-full flex flex-row sm:flex-col md:flex-col md:flex-row justify-between border border-blue_gray-100 p-5 rounded-lg mt-5 mqlap:mt-4 mqtab1:mt-3">
            {/* Benefits Section */}
            <div className="flex flex-col md:w-1/2 gap-5 mqlap:gap-4 mqtab1:gap-3">
                <Text className="text-xl font-semibold mqlap:text-[1.06vw] mqtab1:text-[1.1vw]">Key Benefits</Text>
                {benefits.map((benefit, index) => (
                    <div key={index} className="flex items-center gap-5 mqlap:gap-4 mqtab1:gap-3 ">
                        <ArrowBigRight strokeWidth={2} className="mqlap:w-6 mqlap:h-5 mqtab1:w-6 mqtab1:h-5"  color="#314ca3" />
                        <div  className="flex flex-col gap-2 mqlap:gap-1 mqtab1:gap-[2.8px]">
                            <Text className="text-xl font-semibold mqlap:text-[1.06vw] mqtab1:text-[1.1vw]">{benefit.title}</Text>
                            <Text className="text-md w-[60%] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">{benefit.description}</Text>
                        </div>
                    </div>
                ))}
            </div>

            {/* Plans Section */}
            <div className="flex flex-col items-center md:w-1/2 gap-5">
                <Text className="text-lg font-semibold mqlap:text-[1.04vw] mqtab1:text-[1.08vw]">Choose Plan</Text>
                <div className="flex flex-row md:flex-row justify-around gap-5  w-full">
                    {plans.map((plan) => (
                        <div 
                            key={plan.id}
                            className={`border p-5 mqlap:p-4 mqtab1:p-3 rounded-md flex flex-col items-center justify-between ${selectedPlan === plan.id ? 'border-blue-500 border-[4px] mqlap:border-[3.8px] mqtab1:border-[3.2px]' : 'border-gray-200'}`}
                            style={{ width: '100%' }}
                        >
                            <input
                                type="radio"
                                name="plan"
                                checked={selectedPlan === plan.id}
                                onChange={() => handlePlanSelection(plan.id)}
                                className="mb-2 mqlap:h-[14px] mqlap:w-[14px] mqtab1:h-[12px] mqtab1:w-[12px] mqlap:mb-1 mqtab1:mb-1 "
                            />
                            <Text className="text-lg font-semibold mb-2 mqlap:mb-1 mqtab1:mb-1 mqlap:text-[1.04vw] mqtab1:text-[1.08vw]">{plan.name}</Text>
                            <Text className="text-sm mb-2 mqlap:mb-1 mqtab1:mb-1 text-center mqlap:text-[0.83vw] mqtab1:text-[0.88vw] mqtab1:leading-[17px]">{plan.description}</Text>
                            <Text className="text-xl font-semibold mb-2 mqlap:mb-1 mqtab1:mb-1 mqlap:text-[1vw] mqtab1:text-[1.02vw]">{plan.price} <span className="text-sm mqlap:text-[0.83vw] mqtab1:text-[0.88vw] ml-[9px]">{plan.period}</span></Text>
                        </div>
                    ))}
                </div>
                <Text className="text-sm mqlap:text-[0.83vw] mqtab1:text-[0.88vw]">*Applicable taxes may apply.</Text>
                <Button onClick={handleCheckout} className="w-32 h-10  mqlap:text-[1.04vw] mqlap:w-29 mqlap:h-9 mqtab1:w-26 mqtab1:h-8 mqtab1:rounded-[12px] mqlap:rounded-[14px] mqtab1:text-[1.08vw]">Subscribe</Button>
            </div>
        </div>
    );
};

export default UnPaidUser;
