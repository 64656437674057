import React, { useEffect, useState } from "react";
import Routes from "./Routes";
import supabase from "components/superBaseConfig";
import MainLoader from "components/Loader";
import { setAccountType } from "./actions/AccountType";
import { getPaymentIntents } from "actions/PaymentIntent";
import { fetchNotifications } from "actions/Notification";
import { ProSidebarProvider } from "react-pro-sidebar";
import { fetchJobSearchData } from "actions/JobSearch";
import { fetchAutoJobDetails } from "actions/AutoJobDetails";
import { fetchCareerAdviceData } from "actions/CarrerAdvice";
import { fetchInterviewPrepData } from "actions/InterviewPrepData";
import { fetchProfilePersonalData, fetchProfilePicture } from "actions/ProfilePersonal";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfilePreferenceData } from "actions/ProfilePreference";
import { fetchJobSeekerDashboarCounts } from "actions/JobSeekerDashboard";
import { fetchProfileProfessionalLinks } from "actions/ProfileProfessionalLinks";
import { fetchProfileProfessionalData } from "actions/ProfileProfessionalData";
import { getProfileUploadLinks } from "actions/ProfileUploadLinks";
import { fetchSubscriptionData } from "actions/SubscriptionData";
import { fetchInterviewRequestCount } from "actions/InterviewRequestCount";
import { fetchInterviews } from "actions/InterviewDataAction";

function App() {
  const dispatch = useDispatch();
  const accountType = useSelector((state) => state.profileAccountType.accountType);
  let isLoad = true;
  if (window.location.pathname !== "/" || window.location.pathname !== "/login") isLoad = false;
  const [loading, setLoading] = useState(isLoad);
  const [userFetched, setUserFetched] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const user_id = user.identities[0].user_id;

          if (!user_id || user_id === "") {
            console.error("User_id is missing");
            setLoading(false);
            return;
          }

          if (accountType === 0) {
            await dispatch(setAccountType(user_id));
          }

          setUserFetched(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    if (window.location.pathname !== "/" && window.location.pathname !== "/login") {
      getUserData();
    }
  }, [ dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const user_id = user.identities[0].user_id;
console.log(user_id)
          dispatch(fetchProfilePicture(user_id));
          dispatch(fetchJobSeekerDashboarCounts(user_id));
          dispatch(fetchProfilePersonalData(user_id));
          dispatch(fetchNotifications(user_id));
          dispatch(fetchProfilePreferenceData(user_id));
          dispatch(getProfileUploadLinks(user_id));
          dispatch(fetchProfileProfessionalData(user_id));
          dispatch(fetchProfileProfessionalLinks(user_id));
          dispatch(fetchInterviewPrepData(user_id));
          dispatch(fetchJobSearchData(user_id));
          dispatch(fetchCareerAdviceData(user_id));
      
          dispatch(fetchAutoJobDetails(user_id));
          dispatch(fetchSubscriptionData(user_id));
          dispatch(getPaymentIntents(user_id));
          dispatch(fetchInterviewRequestCount(user_id));
          dispatch(fetchInterviews(user_id));

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (userFetched) {
      fetchData();
    }
  }, [userFetched, dispatch]);

  if (loading) {
    return <div className="h-[100vh] w-full flex flex-col justify-center mb-2 items-center">
      <MainLoader />
    </div>;
  }

  return (
    <ProSidebarProvider>
      <Routes />
    </ProSidebarProvider>
  );
}

export default App;

