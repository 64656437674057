// interviewReducer.js
import {
    FETCH_INTERVIEWS_REQUEST,
    FETCH_INTERVIEWS_SUCCESS,
    FETCH_INTERVIEWS_FAILURE} from "../actions/InterviewDataAction"

const initialState = {
    interviewRequestTable: [],
    loading: false,
    error: null,
};

const interviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INTERVIEWS_REQUEST:
            console.log("FETCH_INTERVIEWS_REQUEST action dispatched");
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_INTERVIEWS_SUCCESS:
            console.log("FETCH_INTERVIEWS_SUCCESS action dispatched", action.payload);
            return {
                ...state,
                loading: false,
                interviewRequestTable: action.payload,
            };
        case FETCH_INTERVIEWS_FAILURE:
            console.log("FETCH_INTERVIEWS_FAILURE action dispatched", action.payload);
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default interviewReducer;
