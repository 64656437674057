import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { fetchInterviewRequestCount } from "actions/InterviewRequestCount";
import { Button as CustomButton } from "components";
import { toPng } from "html-to-image";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profIndicator from "../../../assets/images/interviewReportimg.png";
import logoBase64EncodedValue from "../CareerAdvice/report";
import SubmitTestPopup from "../InterviewPreparation/submitTestPopup";
import TextToSpeech from "../InterviewPreparation/textToSpeech";
import { fetchInterviews } from "actions/InterviewDataAction";
import MainLoader from "components/Loader";
import GaugeChart from "react-gauge-chart";
import { combineReducers } from "redux";
import { useRef } from "react";

pdfMake.vfs = pdfFonts.pdfMake.vfs; // Set fonts for pdfMake

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AssessmentDialog = ({
  open,
  job_id,
  onClose,
  timeRemaining,
  formatTime,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  companyName,
  role,
  duration,
  userID,
  interview_id,
}) => {
  const [speechStatus, setSpeechComplete] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [fetchedQuestions, setFetchedQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [obtdMarks, setObtdMarks] = useState(0);
  const [autoSaveInterval, setAutoSaveInterval] = useState(null);
  const [questionsAnswersArray, setQuestionsAnswersArray] = useState([{}]);
  const [percent, setPercent] = useState(0);
  const [loader, setLoader] = useState(false);
  const marksRef = useRef([]);

  const { personalData } = useSelector((state) => state.profilePersonalData);
  const { id, first_name, last_name } = personalData;

  useEffect(() => {
    if (open) {
      setCurrentQuestionIndex(0);
      fetchQuestionsByJobID(job_id);
      setAnswers([]);
    }
  }, [open, job_id, setCurrentQuestionIndex]);

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        console.log("Auto-saving answers:", answers);
      }, 60000);
      setAutoSaveInterval(interval);
    } else if (autoSaveInterval) {
      clearInterval(autoSaveInterval);
      setAutoSaveInterval(null);
    }
    return () => clearInterval(autoSaveInterval);
  }, [open, answers]);

  const handleAnswerChange = (event) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = event.target.value;
    setAnswers(updatedAnswers);
  };

  const uploadReport = async (pdfFile) => {
    const formData = new FormData();
    // Append the fields to FormData
    formData.append("user_id", userID); // Ensure this matches your API requirement
    formData.append("job_id", job_id); // Make sure this matches what the API expects
    formData.append("int_report", pdfFile); // The generated PDF report

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/upload_job_interview_report`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Upload error: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      console.log("Upload successful!", data);
    } catch (error) {
      console.error("Error uploading report:", error);
      setErrorMessage("Error uploading report. Please try again.");
    }
  };

  const updateInterviewStatus = async (interviewId, obtdMarks) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update_interview_status`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            interview_id: interviewId,
            marks_obtained: obtdMarks,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Status update error: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      dispatch(fetchInterviews(userID));
      console.log("Interview status updated successfully:", data);
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };

  const handleFinalSubmit = async () => {
    setLoader(true);
    console.log("Submitting answers:", answers);
    clearInterval(autoSaveInterval);

    // Construct the request body
    const requestBody = {
      role: role,
      user_id: userID,
      question_answer_list: fetchedQuestions.map((question, index) => ({
        id: String(index),
        question: question.question,
        answer: answers[index] || "",
        weightage: parseInt(question.marks) || 0,
      })),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/job_interview/evaluate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Server error: ${JSON.stringify(errorData)}`);
      }
      const data = await response.json();
      console.log("Evaluation Result:", data);
      setObtdMarks(data.total_score);

      const questionAnswerArray = data.questions.map((item) => ({
        question: item.question,
        answer: item.answer,
        score: item.score || 0,
        max_score: item.max_score || 0,
      }));

      const pdfBlob = await generatePDF(questionAnswerArray, data.total_score);
      await uploadReport(pdfBlob);
      await updateInterviewStatus(interview_id, data.total_score);

      setLoader(false);
      dispatch(fetchInterviewRequestCount(id));
    } catch (error) {
      setErrorMessage("Error submitting answers. Please try again.");
      console.error("Error submitting answers:", error);
    } finally {
      setShowThankYou(true);
      onClose();
      setAnswers([]);
    }
  };

  const handleConfirmSubmit = () => setShowConfirmSubmit(true);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);

    //
    const currentQuestionAnswerPair = {
      question: fetchedQuestions[fetchedQuestions.length - 1],
      answer: answers[answers.length - 1],
    };
    setQuestionsAnswersArray((prev) => [...prev, currentQuestionAnswerPair]);
    //

    setSpeechComplete(false);
    for (let i = 0; i < fetchedQuestions.length; i++) {
      const total = fetchedQuestions.ma;
      const mks = fetchedQuestions.score;
    }
  };

  const fetchQuestionsByJobID = async (jobID) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get_questions_by_jobid`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ jobID }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setFetchedQuestions(data.questions);
      // console.log("fetchedQuestions",data.questions)
      setAnswers(Array(data.questions.length).fill(""));
    } catch (error) {
      setErrorMessage("Error fetching questions. Please try again.");
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionNavigation = (index) => {
    setCurrentQuestionIndex(index);
    setSpeechComplete(false);
  };

  function toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  }

  async function convertToImage() {
    const chart = document.getElementById("gauge-chart1");
    const dataUrl = await toPng(chart);
    return dataUrl;
  }

  const generatePDF = async (questionAnswerArray, totalScore) => {
    try {
      const date = new Date().toLocaleDateString();
      const time = new Date().toLocaleTimeString();
      const score = totalScore;

      setPercent(score / 10);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      const gaugeChartUrl = await convertToImage();

      const dataUrl = await toDataURL(profIndicator);

      const rows = [["Question", "Marks", "Weightage"]];

      for (let i = 0; i < questionAnswerArray.length; i++) {
        const item = questionAnswerArray[i];
        const marks = item.score;
        const weightage = item.max_score;
        const questionText = item.question;
        if (
          marks !== undefined &&
          questionText !== undefined &&
          weightage !== undefined
        ) {
          rows.push([questionText, marks, weightage]);
        }
      }

      let questionsAnswersMarksArray = [];

      for (let i = 0; i < questionAnswerArray.length; i++) {
        const { question, answer, score } = questionAnswerArray[i] || {};

        if (!question || !answer) {
          continue;
        }

        const renderedContent = `Question ${i + 1}: ${question}\n\nMarks: ${
          score || 0
        }\n\nAnswer: ${answer}`;
        questionsAnswersMarksArray.push(renderedContent);
      }

      console.log(questionsAnswersMarksArray);

      let fontColor = "";
      const scorePercentage = score * 10;
      if (scorePercentage >= 60) {
        fontColor = "green";
      } else if (scorePercentage >= 30) {
        fontColor = "blue";
      } else {
        fontColor = "red";
      }

      const docDefinition = {
        pageMargins: [40, 80, 40, 40],
        header: [
          {
            text: "\n\n\n\n\n\n\n",
            columns: [
              {
                image: logoBase64EncodedValue,
                width: 50,
                height: 50,
                margin: [30, 5, 0, 0],
                alignment: "left",
              },
              {
                text: "Interview Report",
                color: "#314CA3",
                fontSize: 18,
                bold: true,
                margin: [0, 15, 0, 0],
                alignment: "left",
              },
            ],
            columnGap: 180,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 800,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
        ],

        content: [
          {
            text: [
              "Full Name: ",
              { text: first_name + " " + last_name, style: "bold" },
              "\n\n",
              "Company Name: ",
              { text: companyName, style: "bold" },
              "\n\n",
              "Role: ",
              { text: role, style: "bold" },
              "\n\n",
              "Duration: ",
              { text: duration, style: "bold" },
              "\n\n",
              "Date and Time: ",
              { text: date + " " + time, style: "bold" },
              "\n\n",
            ],
            margin: [0, 20, 0, 0],
          },

          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 500,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
          {
            columns: [
              {
                text: ["Total Marks: ", { text: totalScore, style: "bold" }],
              },
              {
                text: [
                  "Interview Status: ",
                  { text: "Completed", color: "green" },
                ],
                alignment: "right",
              },
            ],
            margin: [0, 20, 0, 0],
          },

          {
            text: [
              "You are ",
              { text: score * 10 + " % ", color: fontColor },
              "proficient",
            ],
          },
          { image: gaugeChartUrl, width: 300, alignment: "center" },
          { image: dataUrl, width: 300, alignment: "center" },
          {
            table: {
              headerRows: 1,
              body: rows,
            },
            margin: [0, 20, 0, 0],
          },
          {
            text: questionsAnswersMarksArray,
            pageBreak: "before",
            margin: [0, 40, 0, 0],
          },
        ],
      };

      return new Promise((resolve) => {
        pdfMake.createPdf(docDefinition).getBlob((blob) => {
          resolve(blob);
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div style={{ position: "absolute", left: "-9999px" }}>
          <GaugeChart
            id="gauge-chart1"
            animate={false}
            percent={percent}
            needleColor="#464A4F"
            textColor="#000000"
            colors={["#FE4545", "#15BFEB", "#27BB2D"]}
          />
        </div>
        {loader && <MainLoader />}
        <Dialog fullScreen open={open} onClose={onClose}>
          <div className="h-full w-full flex flex-col items-center">
            <AppBar style={{ backgroundColor: "#314ca3" }}>
              <Toolbar className="flex flex-row justify-between items-center">
                <Typography variant="h6">
                  {role} - {companyName} Assessment
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    color: timeRemaining < 300 ? "red" : "inherit",
                  }}
                >
                  Time Remaining: {formatTime(timeRemaining)} mins
                </Typography>
              </Toolbar>
            </AppBar>
            <div className="flex flex-col items-start justify-start mt-[100px] h-full w-[90%]">
              {loading ? (
                <div className="flex justify-center items-center w-full h-full">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <TextToSpeech
                    marks={fetchedQuestions[currentQuestionIndex]?.marks}
                    text={
                      fetchedQuestions[currentQuestionIndex]?.question || ""
                    }
                    index={currentQuestionIndex}
                    handleStartAnswer={() => setSpeechComplete(true)}
                  />

                  <textarea
                    className="w-full rounded-[10px] mt-[10px] sm:text-[14px] text-gray-500 text-md min-h-[200px] border-[#D0D1D4]"
                    value={answers[currentQuestionIndex] || ""}
                    onChange={handleAnswerChange}
                    disabled={!speechStatus}
                  />

                  <div className="flex justify-center mt-4 self-end">
                    {currentQuestionIndex === fetchedQuestions.length - 1 ? (
                      // {currentQuestionIndex === 2 - 1 ? ( */
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmSubmit}
                        disabled={
                          !answers[currentQuestionIndex] || !speechStatus
                        }
                      >
                        Submit Assessment
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextQuestion}
                        disabled={
                          !answers[currentQuestionIndex] || !speechStatus
                        }
                      >
                        Next Question
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </Dialog>

        {/* Confirmation Dialog */}
        <SubmitTestPopup
          openSubmitTest={showConfirmSubmit}
          SubmitTestClose={() => setShowConfirmSubmit(false)}
          handleGetAdvice={() => {
            handleFinalSubmit();
            setShowConfirmSubmit(false);
          }}
        />

        {/* Thank You Dialog */}
        <Dialog
          open={showThankYou}
          TransitionComponent={Transition}
          onClose={() => {
            setShowThankYou(false);
            onClose();
          }}
        >
          <div className="p-6 text-center">
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Thank You for Taking the Test!
            </Typography>
            <Typography variant="body1" style={{ marginTop: "10px" }}>
              Your responses have been submitted successfully. 🎉
            </Typography>
            <Typography variant="body2" style={{ marginTop: "10px" }}>
              We appreciate your time and effort! Keep up the great work!
            </Typography>
            <div className="flex justify-center mt-4">
              <CustomButton
                className="w-[130px]"
                onClick={() => {
                  setShowThankYou(false);
                  onClose();
                }}
              >
                Close
              </CustomButton>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default AssessmentDialog;
