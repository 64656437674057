import { Tooltip, Typography } from '@mui/material';
import { List, Text } from 'components';
import HighchartsComponent from 'components/Graphs';
import MainLoader from 'components/Loader';
import { ProfileHeader } from 'pages/JobSeekerDashBoardPages/ProfileHeader';
import { SideBar1 } from 'pages/JobSeekerDashBoardPages/SideBar1';
import { useSelector } from 'react-redux';
import './index.css';
// import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import HighchartsComponent2 from 'components/Graphs/graph2';
const Dashboard = () => {
  const {
    jobSearchCount,
    liveInterviewCount,
    textInterviewCount,
    careerAdviceCount,
    autoJobCount,
    coverLetterCount,
    resumeBuilderCount,
    loading,
  } = useSelector((state) => state.jobSeekerDashboard);

  const userID = useSelector((state) => state.profilePersonalData.personalData.id);

  const { interviewRequestTable = []} = useSelector((state) => state.interview);
  const [error, setError] = useState(null);

  const { interviewRequestCount } = useSelector((state) => state.interviewRequestReducer)

  const dashboardMetrics = [
    {
      id: 1,
      title: 'Resume Builder',
      count: resumeBuilderCount,
      tooltipContent: 'Number of Resumes built by RecRoid',
    },
    {
      id: 2,
      title: 'Cover Letter',
      count: coverLetterCount,
      tooltipContent: 'Number of Cover Letters made by RecRoid',
    },
    {
      id: 3,
      title: 'Text Interview',
      count: textInterviewCount,
      tooltipContent: 'Number of Text Interviews taken by RecRoid',
    },
    {
      id: 4,
      title: 'Live Interview',
      count: liveInterviewCount,
      tooltipContent: 'Number of Live Interviews taken by RecRoid',
    },
    {
      id: 5,
      title: 'Auto Job Application',
      count: autoJobCount,
      tooltipContent: 'Number of jobs applied on your behalf by RecRoid',
    },
    {
      id: 6,
      title: 'Job Search',
      count: jobSearchCount,
      tooltipContent: 'Number of relevant job openings shared by RecRoid',
    },
    {
      id: 7,
      title: 'Career Advice',
      count: careerAdviceCount,
      tooltipContent: 'Number of Career Advice sessions given by RecRoid',
    },
    {
      id: 8,
      title: 'Interview Requests',
      count: interviewRequestCount,
      tooltipContent: 'Total number of interview requests provided by RecRoid',
    },
  ];

  return (
    <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full h-[100vh] overflow-hidden">
      <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full h-[100vh] overflow-hidden">
        <SideBar1 clickedTab="Dashboard" />
        <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh] overflow-hidden ">
          <ProfileHeader clickedTab="Jobseeker Dashboard" />
          {loading && <MainLoader />}
          <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start rounded-[15px] w-[94%] md:w-full h-[93vh] overflow-x-hidden">
            <div className="flex flex-col items-center justify-center py-[50px] w-[89%] mqlap:w-[92%]  mqtab1:w-[92%] mqmob1:w-[92%]">
              <div className="flex flex-row gap-4 sm:gap-8 items-center justify-center w-[89%] mqlap:w-[92%]  mqtab1:w-[92%] mqmob1:w-[95%]">
                <List className="grid gap-4 sm:gap-8 md:gap-12 sm:grid-cols-1 md:grid-cols-2 grid-cols-4 w-full flex flex-row items-center justify-center mqmob1:gap-x-[1.5rem] mqmob1:gap-y-[2rem]">
                  {dashboardMetrics.map((metric) => (
                    <Tooltip
                      title={<Typography fontSize={18}>{metric.tooltipContent}</Typography>}
                      placement="bottom"
                      arrow
                      key={metric.id}
                      className="flex flex-col items-center justify-start gap-2 h-[140px] border-[0.1px] border-[#6d69697f] border-solid rounded-[15px] p-[5px] pt-[12px] pb-[12px] pr-0 mqlap:pr-[10px] transition-colors hover:bg-gray-100 hover:text-[40px] mqlap:h-[140px] mqtab1:h-[125px] mqtab2:h-[157px] mqmob1:h-[145px]"
                    >
                      <div>
                        <Text className="text-2xl sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl mqlap:text-[1.32rem] mqtab1:text-[1.15rem] self-start pl-[15px] text-blue_gray-400 leading-normal" size="txtPoppinsSemiBold24">
                          {metric.title}
                        </Text>
                        <Text className="sm:text-4xl sm:text-[18px] self-start pl-[15px] text-[36px] mqlap:text-[31px] mqtab1:text-[28px] text-indigo-600 w-auto" size="txtPollerOneRegular40">
                          {metric.count || 0}
                        </Text>
                      </div>
                    </Tooltip>
                  ))}
                </List>
              </div>
            </div>
            <div className="flex flex-col w-auto mb-4">
              
              {!loading && (
                <div className="flex flex-wrap gap-8 max-w-[80%] self-center p-4 rounded-lg">
                 
                  <div className="flex-1 min-w-[300px] md:min-w-[350px] lg:min-w-[400px] xl:min-w-[450px] bg-white shadow-lg p-4 rounded-lg">
                  <h1 className="sm:text-[18px] pb-[30px] text-blue_gray-400 text-[28px] text-center mqtab1:text-[23px] mqlap:text-[25px] mqmob1:text-[26px] ">
                Interview Scores
              </h1>
              <HighchartsComponent2 scoreData={interviewRequestTable} />
                  
                  </div>
                  <div className="flex-1 min-w-[300px] md:min-w-[350px] lg:min-w-[400px] xl:min-w-[450px] bg-gray-50 shadow-lg p-4 rounded-lg">
                  <h1 className="sm:text-[18px] pb-[30px] text-blue_gray-400 text-[28px] text-center mqtab1:text-[23px] mqlap:text-[25px] mqmob1:text-[26px] ">
               Mock Interview Scores
              </h1>
              <HighchartsComponent/>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
