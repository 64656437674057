import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Typography } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Button, Text } from "components";
const InstructionDialog = ({ open, onClose, onStartAssessment, duration,noOfQuestions }) => {
    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <div className="bg-gray-100 h-auto">
                <AppBar
                    style={{ backgroundColor: "#314ca3" }}
                    sx={{ fontSize: { sm: "12px" } }}
                >
                    <Toolbar>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                            className="sm:text-[1px]"
                        >
                            <Text className="text-xl sm:text-[14px]">
                                Please Read Instruction Carefully
                            </Text>
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="overflow-auto pb-[40px] pt-[50px] text-gray-500 gap-[50px] flex flex-col justify-center items-center">
                <div
                        style={{ backgroundColor: "#ffffff" }}
                        className="mt-[6.5rem] pl-[4.5%] pr-[3%] w-[52vw] sm:w-[80vw] h-auto pb-[40px] v-[50vw]"
                    >
                        <Text
                            className="text-xl  pb-[30px] pt-[26px] sm:text-sm"
                            size="txtInterMedium20"
                        >
                            Instructions
                        </Text>
                        <ul className="list-disc w-full space-y-5  sm:text-[10px]">
                            <li className="mb-5">Duration: {duration} minutes</li>
                            <li className="mb-5">
                                Number of questions: {noOfQuestions}
                            </li>
                            <li className="mb-5">Answer all questions.</li>


                            <li className="mb-5">
                                Ensure you have a stable internet connection.
                            </li>
                            <li className="mb-5">Review your answers before submitting.</li>
                            <li className="mb-5">
                                Once submitted, you cannot retake the exam.
                            </li>
                            <li className="mb-5">
                            The test is not auto-saved. You must manually proceed by clicking "Next" after answering each question.
                            </li>
                            <li className="mb-5">
                            Review your answers before moving to the next question, as there is no way to go back.
                            </li>
                            <li className="mb-5">
                            Once the total time elapses, the test will be automatically submitted.
                            </li>
                            
                        </ul>

                        <Text
                            className="text-xl  pb-[30px] pt-[20px] sm:text-sm mt-[3rem]"
                            size="txtInterMedium20"
                        >
                            Additional Guidelines
                        </Text>
                        <ul className="list-disc w-full sm:text-[10px]">
                        <li className="mb-5">
                        Environment: Ensure you are in a quiet environment to avoid distractions.
                        </li>
                        <li className="mb-5">
                            Integrity: Complte the test independently and honestly, without external help or resources (internet, books, etc).
                        </li>
                        <li className="mb-5">
                        Interruptions: If interrupted, the test will not be paused or restarted, so make sure you are in a setting where you can focus for the full duration.
                        </li>
                        </ul>
                       
                    </div>

                    <Button
                        className="flex justify-center items-center mr-[30px] h-[2.55rem] w-[8.5rem] mqlap:h-[2.15vw] mqlap:w-[6.9vw] mqtab1:h-[2.2vw] mqtab1:w-[7vw] mqtab2:h-[2.36vw] mqtab2:w-[7.5vw] rounded-[12px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                        onClick={onStartAssessment}
                    >
                        Start Interview
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default InstructionDialog;
