// interviewActions.js
export const FETCH_INTERVIEWS_REQUEST = 'FETCH_INTERVIEWS_REQUEST';
export const FETCH_INTERVIEWS_SUCCESS = 'FETCH_INTERVIEWS_SUCCESS';
export const FETCH_INTERVIEWS_FAILURE = 'FETCH_INTERVIEWS_FAILURE';

export const fetchInterviewsRequest = () => ({
    type: FETCH_INTERVIEWS_REQUEST,
});

export const fetchInterviewsSuccess = (interviews) => ({
    type: FETCH_INTERVIEWS_SUCCESS,
    payload: interviews,
});

export const fetchInterviewsFailure = (error) => ({
    type: FETCH_INTERVIEWS_FAILURE,
    payload: error,
});

export const fetchInterviews = (userID) => {
    return async (dispatch) => {
        dispatch(fetchInterviewsRequest());
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_interviews_by_userid`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userID }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log(111111,data)
            dispatch(fetchInterviewsSuccess(data.interviews));
        } catch (error) {
            dispatch(fetchInterviewsFailure(error.message));
        }
    };
};
