import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography
} from "@mui/material";
import { Button, Img, Text } from "components";
export const EditIcon = ({ onClick }) => (
  <div>
    <img
      className="sm:h-[15px] bxs-edit mqlap:h-[2.7vw] mqlap:w-[1.85vw]  mqtab1:h-[2.8vw] mqtab1:w-[2.1vw]"
      alt="Bxs edit"
      src="https://c.animaapp.com/OxnFMjQi/img/bxs-edit-1.svg"
      onClick={onClick}
    />
  </div>
);
export const DownArrow = () => (
  <img
    className="icon-park-outline  sm:h-[15px] "
    // onClick={workExpInitially}
    alt="Icon park outline"
    src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
  />
);
export const UpArrow = () => (
  <img
    className="icon-park-outline sm:h-[15px] "
    // onClick={workExpInitially}
    alt="Icon park outline"
    src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
  />
);

export const AddCancelSection = ({
  newPosition,
  setNewPosition,
  addNewWorkExperience,
  cancelAdd,
  page,handleDataUpdate
}) => {
  return (
    <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
      <div class="w-full sm:ml-[31px] sm:flex sm:flex-col">
      <input
        placeholder={page}
        className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
        value={newPosition}
        onChange={(e) =>{setNewPosition(e.target.value);handleDataUpdate()}}
        required
      /></div>
     
      <div className="flex flex-row items-end justify-end gap-5 sm:gap-4 relative  ">
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={ addNewWorkExperience}
                  >
                    Add
                  </Button>
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={cancelAdd}
                  >
                    Cancel
                  </Button>
                </div>     
    </div>
  );
};

export const AddCancelSection2 = ({
  addNewWorkExperience,
  cancelAdd,
  page
}) => (
<div className="flex flex-row items-end justify-end gap-5 sm:gap-4 relative  ">
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={ addNewWorkExperience}
                  >
                    Add
                  </Button>
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={cancelAdd}
                  >
                    Cancel
                  </Button>
                </div>
);

export const SaveCancleSection = ({ saveChanges, cancleChanges, page }) => (
  <div className="flex flex-row items-end justify-end gap-5 sm:gap-4 relative sm:relative  sm:top-[3px]">
    <Button
    className="w-[8.4rem] h-[50px]  mb-3 mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"

      onClick={saveChanges}
    >
      Save
    </Button>
    <Button
      className="w-[8.4rem] h-[50px]  mb-3 mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px] sm:relative  sm:right-[8px]"
      onClick={cancleChanges}
    >
      Cancel
    </Button>
  </div>
);

export const ToggleArrow = ({ toggleDeclaration, editWork,imgPath,sectionHeading }) => (
  <div className="flex flex-1 flex-row md:gap-10 items-center justify-between p-4">
  <div className="flex flex-row gap-[18px] items-center justify-center ml-[33px]">
          <Img
            className=" sm:object-contain  sm:h-[15px] sm:w-[15px]  h-[25px] md:h-auto object-cover w-[25px] mqlap:w-[1.4vw] mqlap:h-[1.4vw] mqtab1:w-[1.6vw] mqtab1:h-[1.6vw]"
            src={imgPath}
            alt="brainstormskill"
          />
          <Text
             className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]"
            size="txtInterSemiBold18"
          >
            {sectionHeading}
          </Text>
        </div>
  <div className="flex flex-row">
    <img
      className="icon-park-outline sm:h-[15px] sm:w-[15px] mqlap:h-[2.6vw]  mqtab1:h-[3vw] "
      onClick={toggleDeclaration}
      alt="Icon park outline"
      src={
        editWork
          ? "https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
          : "https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
      }
    />
  </div>
  </div>
);

// export const EditDeleteIconsSection = ({
//   index,
//   editItemButton,
//   deleteWork,
// }) => (
//   <div className="flex flex-row w-[15%] justify-end mr-[4%]">
//     <img
//       className="bxs-edit sm:h-[15px] sm:w-[15px]"
//       onClick={() => editItemButton(index)}
//       alt="Bxs edit"
//       src="https://c.animaapp.com/OxnFMjQi/img/bxs-edit-1.svg"
//     />
//     <Img
//       className="bxs-edit sm:h-[15px] sm:w-[15px]"
//       onClick={() => deleteWork(index)}
//       src="images\img_delete1.svg"
//     />
//   </div>
// );
export const EditDeleteIconsSection = ({
  index,
  editItemButton,
  deleteWork,
}) => {
  const [deletePopup, setDeletePopup] = useState(false);

  const handleDeleteButton = () => {
    setDeletePopup(true);
  };

  return (

      <div className="flex flex-row w-[15%] justify-end mr-[4%]">
        <div  className="flex flex-row justify-center items-end mr-[4%]">
        <img
          className="sm:h-[15px] bxs-edit mqlap:h-[1.85vw] mqlap:w-[1.85vw]  mqtab1:h-[2.1vw] mqtab1:w-[2.1vw]"

          onClick={() => editItemButton(index)}
          alt="Edit"
          src="https://c.animaapp.com/OxnFMjQi/img/bxs-edit-1.svg"
        />
        <img
          className="bxs-delete sm:h-[15px] mqlap:h-[1.6vw] mqlap:w-[1.6vw]  mqtab1:h-[1.8vw] mqtab1:w-[1.8vw]"
          onClick={handleDeleteButton}
          alt="Delete"
          src="images/img_delete1.svg"
        />
        </div>
      
      <Dialog
      maxWidth="xxl"
      open={deletePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
    >
      {/* <DialogTitle id="alert-dialog-title">
      {"Confirm submission of test?"}
    </DialogTitle> */}
      <div className="mqlap:w-[33.2vw] mqtab1:w-[35.2vw]">
        <div>
          <div className="flex flex-row items-center h-[71px] mqlap:h-[3.58vw] mqtab1:h-[3.76vw] mqtab2:h-[45px]">
            <Text
              className="pl-[4.5%] mt-0.5 text-black-900 text-xl sm:text-[12px]   mqlap:text-[1.05vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]"
              size="txtInterSemiBold18"
            >
              Sure you want to delete?
            </Text>
            <Tooltip
              title={
                <Typography>
                  <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                  Click Yes button to delete.
                  </p>
                </Typography>
              }
              placement="right"
              arrow
              className=" relative flex flex-col items-center justify-start md:h-[84px]  md:w-[250px] rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px] "
            >
              <img
                src="images/infoIcon.svg"
                alt="infoIcon"
                className="mt-[1.3px] mqlap:mt-[1px] mqtab1:mt-[2px] ml-[3px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.1vw] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
              />
            </Tooltip>
          </div>
          <hr
            style={{
              height: "0.5px",
              backgroundColor: "grey",
              border: "none",
              width: "100%",
            }}
          />
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="pl-[0.5%] text-[1.05rem] mqlap:text-[0.92vw] mqtab1:text-[0.96vw] mqtab2:text-[1.02vw] leading-6 mqlap:leading-[1.45vw] mqtab1:leading-[1.5vw]">
            Please verify this before proceeding with the deletion, as it will be permanent.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-[0.57rem] mqtab1:mb-[0.43rem]  mqlap:mb-[0.5rem] mt-[0.2vw] mqtab1:mt-[0.1vw] ">
          <button
            style={{ borderColor: "blue", color: "blue" }}
            className=" mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid flex justify-center items-center h-[2.37rem] w-[7.3rem] mqlap:h-[2vw] mqlap:w-[6.35vw] mqtab1:h-[2.1vw] mqtab1:w-[6.7vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
            onClick={() => setDeletePopup(false)}
          >
            No
          </button>
          <Button
            onClick={() => {
              deleteWork(index);
              setDeletePopup(false);
            }}
            className="flex justify-center items-center mr-[30px] mqlap:mr-[1.8vw] mqtab1:mr-[1.9vw] h-[2.38rem] w-[7.3rem] mqlap:h-[2.05vw] mqlap:w-[6.5vw] mqtab1:h-[2.15vw] mqtab1:w-[6.7vw] mqtab2:h-[2.36vw] mqtab2:w-[7.4vw] rounded-[12px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
      </div>
  );
};

export const AddMoreSection = ({ handleAddMoreClick }) => (
  <div
    className="flex flex-row gap-[9px] items-center justify-start mb-[3px] md:ml-[0] sm:ml-[35px] ml-[87px] md:px-5 w-[20%] md:w-full"
    onClick={handleAddMoreClick}
  >
    <Img
      className="h-5 w-5 mb-4 sm:h-[13px] sm:w-[13px] sm:relative sm:top-[2px] mqlap:w-[1vw] mqlap:h-[1vw] mqtab1:w-[1.1vw] mqtab1:h-[1.1vw]"
      src="images/img_plus.svg"
      alt="plus"
    />
    <Text
      className="text-[15px] text-black-900 pb-3 w-[100%] sm:text-[11px] mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
      size="txtInterRegular15"
    >
      Add more
    </Text>
  </div>
);

export const ShowAllSection = ({ arrayLength, toggleShowAll,textTag }) => (
  <div
    className="flex flex-row items-center justify-center mt-5 mb-5"
    onClick={toggleShowAll}
  >
    <Text className="text-wrapper-3 sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[1vw]">
      show all {arrayLength} {textTag}
    </Text>
    <Img
      className="rightarrow1 h-[25px] sm:h-[15px] sm:w-[15px] md:h-auto object-cover w-[30px] p-1 ml-3"
      src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
      alt="brainstormskill"
    />
  </div>
);
