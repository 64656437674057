import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchInterviews } from 'actions/InterviewDataAction';
import InterviewTable from './interviewTable';
import InterviewPagination from './InterviewPagination';
import StartInterviewDialog from './StartInterviewDialog';
import InstructionDialog from './InstructionDialog';
import AssessmentDialog from './AssessmentDialog';
import MainLoader from 'components/Loader';

const InterviewRequestTable = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [instructionDialogOpen, setInstructionDialogOpen] = useState(false);
    const [assessmentDialogOpen, setAssessmentDialogOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();
    const { interviewRequestTable = [], error, loading } = useSelector((state) => state.interview);
    const userID = useSelector((state) => state.profilePersonalData.personalData.id);

    // Parse duration from minutes to seconds
    const parseDuration = (duration) => duration * 60;

    // Start the interview and set the initial time remaining
    const startInterview = (job) => {
        setSelectedJob(job);
        setTimeRemaining(parseDuration(job.job_details.duration));
        setDialogOpen(true);
    };

    // Timer effect: decreases timeRemaining every second
    useEffect(() => {
        if (assessmentDialogOpen && timeRemaining > 0) {
            const timer = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);

            // Clear the timer when timeRemaining reaches 0 or when component unmounts
            return () => clearInterval(timer);
        } else if (timeRemaining === 0) {
            // Handle end of time (e.g., auto-submit assessment)
            setAssessmentDialogOpen(false);
            handleSubmitAssessment();
        }
    }, [assessmentDialogOpen, timeRemaining]);

    const questionsArray = selectedJob?.job_details?.questions || [];

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAnswerChange = (event) => {
        const { value } = event.target;
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestionIndex] = value;
        setAnswers(updatedAnswers);
    };

    const handleSubmitAnswer = () => {
        if (currentQuestionIndex < questionsArray.length - 1) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        } else {
            handleSubmitAssessment();
        }
    };

    const handleSubmitAssessment = () => {
        // console.log('Submitted Answers:', answers);
        setAssessmentDialogOpen(false);
    };

    const tableData = interviewRequestTable.slice((currentPage - 1) * 5, currentPage * 5);

    return (
        <div className="mb-[30px] w-full">
            {loading && <MainLoader/>}
        
            <InterviewTable
                tableData={tableData}
                startInterview={startInterview}
                job_id={selectedJob?.jobID}
                error={error}
                userID={userID}
            />
            <InterviewPagination
                currentPage={currentPage}
                totalItems={interviewRequestTable.length}
                itemsPerPage={5}
                handlePageChange={handlePageChange}
            />
            <StartInterviewDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                selectedJob={selectedJob}
                onConfirm={() => {
                    setDialogOpen(false);
                    setInstructionDialogOpen(true);
                }}
            />
            <InstructionDialog
                open={instructionDialogOpen}
                duration={selectedJob?.job_details?.duration}
                noOfQuestions={selectedJob?.job_details?.no_of_questions}
                onClose={() => setInstructionDialogOpen(false)}
                onStartAssessment={() => {
                    setInstructionDialogOpen(false);
                    setAssessmentDialogOpen(true);
                }}
            />
            <AssessmentDialog
                open={assessmentDialogOpen}
                onClose={() => setAssessmentDialogOpen(false)}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                timeRemaining={timeRemaining}
                formatTime={(time) => `${Math.floor(time / 60)}:${time % 60 < 10 ? '0' : ''}${time % 60}`}
                currentQuestionIndex={currentQuestionIndex}
                questionsArray={questionsArray}
                answers={answers}
                job_id={selectedJob?.jobID}
                handleAnswerChange={handleAnswerChange}
                handleSubmitAnswer={handleSubmitAnswer}
                companyName={selectedJob?.job_details?.company}
                role={selectedJob?.job_details?.role}
                userID={userID}
                duration={selectedJob?.job_details?.duration}
                interview_id={selectedJob?.id}
            />
        </div>
    );
};

export default InterviewRequestTable;
