import Highcharts from "highcharts";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

const HighchartsComponent = () => {
  const chartRef = useRef(null);
  const [selectedRange, setSelectedRange] = useState("currentMonth");
  const [textInterview, setTextInterview] = useState([]);
  const [liveInterview, setLiveInterview] = useState([]);
  const [noOfDays, setNoOfDays] = useState(28);


  const dispatch = useDispatch();
  const scoreData = useSelector((state) => state.interviewPrepData.data);
  const isLoading = useSelector((state) => state.interviewPrepData.isLoading);

  useEffect(() => {

    const finalData = scoreData.map((item) => ({
      description: item.interview_type,
      score: item.overall_rating,
      date: item.date,
      time_stamp: item.created_at,
    }));

    finalData.sort((a, b) => new Date(b.time_stamp) - new Date(a.time_stamp));

    const textFilter = finalData.filter((entry) =>
      entry.description.includes("Text"),
    );
    const liveFilter = finalData.filter((entry) =>
      entry.description.includes("Live"),
    );

    setTextInterview(textFilter);
    setLiveInterview(liveFilter);
  }, [scoreData]);

  const generateChartData = (interviewType) => {
    const data =
      interviewType === "liveInterview" ? liveInterview : textInterview;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - noOfDays);

    return data
      .filter((entry) => new Date(entry.date) >= startDate)
      .map((entry) => [new Date(entry.date).getTime(), entry.score]);
  };

  const calculateTickInterval = (range) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    switch (range) {
      case "currentMonth":
        return 24 * 3600 * 1000; // one day
      case "lastMonth":
        return endOfMonth - startOfMonth;
      default:
        return 24 * 3600 * 1000; // default to one day
    }
  };

  const chartConfig = {
    xAxis: {
      type: "datetime",
      title: { text: "Date" },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%d %b", +this.value);
        },
      },
      tickInterval: calculateTickInterval(selectedRange),
      gridLineColor: "#c8c6c6",
      gridLineWidth: 1,
      lineColor: "#333",
    },
    title: { text: "" },
    credits: { enabled: false },
    series: [
      {
        name: "Live Mock Interview Score",
        type: "line",
        data: generateChartData("liveInterview"),
      },
      {
        name: "Text Mock Interview Score",
        type: "line",
        data: generateChartData("textInterview"),
        color: "#e24f23",
      },
    ],
    yAxis: {
      title: { text: "Score" },
      gridLineColor: "#cecaca",
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(chartRef.current, chartConfig);
    }
  }, [chartConfig]);

  const handleDaysChange = (e) => {
    const selectedDays = parseInt(e.target.value, 10);
    setNoOfDays(selectedDays);
  };

  return (
    <div className="flex flex-col justify-center items-center w-auto">
      <div className="flex flex-row justify-end mb-4 w-full sm:w-[80vw]">
        <select
          value={noOfDays}
          onChange={handleDaysChange}
          className="h-[40px] w-[130px]  border-[0.5px] border-[#6d69697f] border-solid text-blue_gray-400 font-medium rounded-[10px] sm:text-[8px] text-[12px] ml-[700px]"
        >
          <option disabled>Select duration</option>
          <option value={7} selected>
            Last 7 days
          </option>
          <option value={15}>Last 15 days</option>
          <option value={28}>Last 1 month</option>
        </select>
      </div>

      {isLoading ? (
        <BeatLoader />
      ) : (
        <>
          {scoreData.length === 0 ? (
            <p style={{ color: "#314ca3" }} className="mt-4">
              No interview data found in this particular duration.
            </p>
          ) : (
            <div
              className="sm:w-[80vw] sm:text-[10px] text-base w-[95%]  p-[11px] "
              ref={chartRef}
            ></div>
          )}
        </>
      )}
    </div>
  );
};

export default HighchartsComponent;
