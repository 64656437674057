const initialState = {
  interviewRequestCount: null,
  loading: false,
  error: null,
};

const interviewRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_INTERVIEW_REQUEST_COUNT_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'FETCH_INTERVIEW_REQUEST_COUNT_SUCCESS':
      return {
        ...state,
        interviewRequestCount: action.payload.interview_count, // Ensure correct key
        loading: false,
        error: null,
      };
    case "FETCH_INTERVIEW_REQUEST_COUNT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default interviewRequestReducer;
