import MainLoader from 'components/Loader';
import { ProfileHeader } from 'pages/JobSeekerDashBoardPages/ProfileHeader';
import { SideBar1 } from 'pages/JobSeekerDashBoardPages/SideBar1';
import { useSelector } from 'react-redux';
import InterviewRequestTable from './interviewRequestPage';

const InterviewRequestPage = () => {
    const {
        loading,
    } = useSelector((state) => state.jobSeekerDashboard);



    return (
        <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full h-[100vh] overflow-hidden">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full h-[100vh] overflow-hidden">
                <SideBar1 clickedTab="Interviews" />
                <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh] overflow-hidden">
                    <ProfileHeader clickedTab="Interviews" />
                    {loading && <MainLoader />}
                    <div className="bg-white-A700 flex flex-col font-poppins items-center justify-start rounded-[15px] w-[94%] md:w-full h-[93vh] overflow-x-hidden">
                        <div className="flex flex-col items-center justify-center py-[50px] w-[89%]">
                            <InterviewRequestTable />
                        </div>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default InterviewRequestPage;
