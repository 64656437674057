export const fetchInterviewRequestCount = (userID) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_INTERVIEW_REQUEST_COUNT_REQUEST" });
    try {
      const backendUrl = `${process.env.REACT_APP_API_URL}/get_interview_count_by_userid`;
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({ userID }), // Ensure this key matches your API
      });

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Fetched data:", data);

      dispatch({
        type: "FETCH_INTERVIEW_REQUEST_COUNT_SUCCESS",
        payload: data,
      });
      
    } catch (error) {
      dispatch({
        type: "FETCH_INTERVIEW_REQUEST_COUNT_FAILURE",
        payload: error.message,
      });
      console.error("Error fetching interview request count:", error.message);
    }
  };
};
