import { Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@mui/material';
import { Button } from 'components';

const StartInterviewDialog = ({ open, onClose, selectedJob, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth> 
            <DialogContent>
                {selectedJob ? (
                    <div className="flex flex-col   mqlap:w-[39.5vw] mqtab1:w-[42.8vw] mqtab2:w-[45vw] mqmob1:w-[48vw] ">
                        <div className="h-[71px] mqlap:h-[4.1vw] mqtab1:h-[4.25vw] mqtab2:h-[4.1vw] flex justify-start items-center gap-[3px] mqlap:gap-[2px] mqtab1:gap-[2px] flex-wrap">
                            <p className="text-start font-semibold text-[20px] text-xl sm:text-sm mqlap:text-[1.1vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]">
                                Start Interview
                            </p>
                        </div>
                        <hr
                            style={{
                                height: "1px",
                                backgroundColor: "#e3e4e8",
                                border: "none",
                                width: "100%",
                            }}
                        />
                        <div className="flex-wrap flex flex-col sm:ml-[10px] sm:mr-[10px]  mqlap:ml-[65px] mqlap:mr-[40px] mqtab1:ml-[65px] mqtab1:mr-[40px] gap-y-[22px] gap-x-[30px]  mqtab1:gap-y-[20px] mqtab1:gap-x-[28px] items-start sm:flex-col mt-[20px]">
                            <div className="flex flex-row gap-[10px]">
                                <p className="sm:text-[10px] font-semibold  mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                                    Company :
                                </p>
                                <p>{selectedJob.job_details.company}</p>
                            </div>
                            <div className="flex flex-row gap-[10px]">
                                <p className="sm:text-[10px] font-semibold  mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                                    Role :
                                </p>
                                <p>{selectedJob.job_details.role}</p>
                            </div>
                            <div className="flex flex-row gap-[10px]">
                                <p className="sm:text-[10px] font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                                   Duration :
                                </p>
                                <p>{selectedJob.job_details.duration} minutes</p>
                            </div>
                            <div className="flex flex-row gap-[10px]">
                                <p className="sm:text-[10px] font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                                    No.of Questions :
                                </p>
                                <p>{selectedJob.job_details.no_of_questions}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>No job selected</p>
                )}
            </DialogContent>
          
            <DialogActions>
                <div className="flex flex-row gap-[18px] mqlap:gap-[15px] mqtab1:gap-[14.5px] mqtab2:gap-[13px] justify-end sm:justify-center items-center mt-[2.3rem] mb-[1.15rem] mqtab1:mb-[1.05rem] mqtab1:mt-[2.1rem] mqlap:mb-[1.07rem]">
                    <button
                        style={{ borderColor: "blue", color: "blue" }}
                        className=" mqtab1:border-[0.1vw] mqlap:border-[0.1vw]  mqtab2:border-[0.1vw] border-[1.8px] border-solid border-blue flex justify-center items-center h-[2.52rem] w-[7.5rem] mqlap:h-[2.11vw] mqlap:w-[6.8vw] mqtab1:h-[2.15vw] mqtab1:w-[6.9vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <Button
                        className="flex justify-center items-center mr-[30px] h-[2.55rem] w-[8.5rem] mqlap:h-[2.15vw] mqlap:w-[6.9vw] mqtab1:h-[2.2vw] mqtab1:w-[7vw] mqtab2:h-[2.36vw] mqtab2:w-[7.5vw] rounded-[12px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                        onClick={onConfirm}
                    >
                        Start Interview
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default StartInterviewDialog;
