import { min } from "date-fns";
import Highcharts from "highcharts";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

const HighchartsComponent2 = ({ scoreData }) => {
  const chartRef = useRef(null);
  const [noOfDays, setNoOfDays] = useState(28);
  const isLoading = useSelector((state) => state.interviewPrepData.isLoading);

  // Filter only completed tests and map to required format
  const filteredData = scoreData
    .filter((item) => item.status === 1)
    .map((item) => ({
      jobRole: item.job_details.role,
      score: item.marks_obtained, // Ensure marks is the correct field
      date: new Date(item.job_details.last_date),
    }))
    .filter((item) => {
      const diffTime = new Date() - item.date;  // Calculate difference in time
      const diffDays = diffTime / (1000 * 3600 * 24);  // Convert to days
      return diffDays <= noOfDays;  // Only keep items within the selected range
    })
    .sort((a, b) => a.date - b.date);

  // Generate chart data points from filtered data
  const generateChartData = () => {
    return filteredData.map((item) => [  item.date.getTime(),item.score
    ]);
  };

  const chartConfig = {
    xAxis: {
      type: "datetime",
      title: { text: "Date" },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%d %b", this.value);
        },
      },
      gridLineColor: "#c8c6c6",
      gridLineWidth: 1,
      lineColor: "#333",
    },
    title: { text: "" },
    credits: { enabled: false },
    series: [
      {
        name: "Completed Interview Scores",
        type: "line",
        data: generateChartData(),
        color: "#314ca3",
      },
    ],
    yAxis: {
      title: { text: "Score" },
      gridLineColor: "#cecaca",
      min: 0, 
      max: 10,
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(chartRef.current, chartConfig);
    }
  }, [filteredData]);  // Re-render the chart when filteredData changes

  const handleDaysChange = (e) => {
    setNoOfDays(parseInt(e.target.value, 10));
  };

  return (
    <div className="flex flex-col justify-center items-center w-auto">
      <div className="flex flex-row justify-end mb-4 w-full sm:w-[80vw]">
        <select
          value={noOfDays}
          onChange={handleDaysChange}
          className="h-[40px] w-[130px] border-[0.5px] border-[#6d69697f] text-blue_gray-400 font-medium rounded-[10px] sm:text-[8px] text-[12px]"
        >
          <option disabled>Select duration</option>
          <option value={7}>Last 7 days</option>
          <option value={15}>Last 15 days</option>
          <option value={28}>Last 1 month</option>
        </select>
      </div>

      {isLoading ? (
        <BeatLoader />
      ) : (
        <>
          {filteredData.length === 0 ? (
            <p style={{ color: "#314ca3" }} className="mt-4">
              No interview data found in this particular duration.
            </p>
          ) : (
            <div className="sm:w-[80vw] p-[11px]" ref={chartRef}></div>
          )}
        </>
      )}
    </div>
  );
};

export default HighchartsComponent2;
