import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Img, Text } from 'components';

const InterviewTable = ({ tableData, startInterview, userID, error }) => {
    const downloadPDF = async (jobID) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/download_job_interview_report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userID,
                    jobID: jobID,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to download report: ${response.statusText}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'InterviewReport.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            alert("Error downloading the report. Please try again.");
            console.error("Download error:", error);
        }
    };

    return (
        <TableContainer component={Paper} className="w-full">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Text className="text-base text-blue_gray-400">S. No.</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Role</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Company</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Status</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Last Date</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Duration</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Questions</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Marks Obtained</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Max. Marks</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Start Interview</Text></TableCell>
                        <TableCell><Text className="text-base text-blue_gray-400">Download PDF</Text></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {error ? (
                        <TableRow>
                            <TableCell colSpan={11} className="w-full text-center">No interviews found</TableCell>
                        </TableRow>
                    ) : (
                        tableData.map((row, index) => (
                            <TableRow key={row.sno}>
                                <TableCell><Text>{index + 1}</Text></TableCell>
                                <TableCell><Text>{row.job_details.role}</Text></TableCell>
                                <TableCell><Text>{row.job_details.company}</Text></TableCell>
                                <TableCell><Text>{row.status==0?"Pending":"Completed"}</Text></TableCell>
                                <TableCell><Text>{row.job_details.last_date || "N/A"}</Text></TableCell>
                                <TableCell><Text>{row.job_details.duration} mins</Text></TableCell>
                                <TableCell><Text>{row.job_details.no_of_questions}</Text></TableCell>
                                <TableCell><Text>{row.marks_obtained}</Text></TableCell>
                                <TableCell><Text>{row.job_details.max_marks || "N/A"}</Text></TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => startInterview(row)}
                                        disabled={row.status === 1}
                                    >
                                        Start
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Img
                                        src="images/img_octicondownload162.svg"
                                        alt="Download PDF"
                                        onClick={() => {
                                            if (row.status === 1) {
                                                downloadPDF(row.jobID); // Pass the correct job ID
                                            } else {
                                                alert("Complete the interview to download the PDF.");
                                            }
                                        }}
                                        style={{
                                            cursor: row.status === 1? "pointer" : "not-allowed",
                                            opacity: row.status === 1 ? 1 : 0.5,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InterviewTable;
