import AllResumeTemplates from "components/AllResumeTemplates";
import BillingHistory from "pages/JobSeekerDashBoardPages/BillingHistory";
import BillingOverview from "pages/JobSeekerDashBoardPages/BillingOverview";
import BillingPreferences from "pages/JobSeekerDashBoardPages/BillingPreferences";
import CareerAdvise from "pages/JobSeekerDashBoardPages/CareerAdvice";
import CoverLetterDetails from "pages/JobSeekerDashBoardPages/CoveLetterDetails";
import CoverLetter from "pages/JobSeekerDashBoardPages/CoverLetter";
import CoverletterDowload from "pages/JobSeekerDashBoardPages/CoverletterDownload";
import DesktopResetPasswordemailAddress from "pages/JobSeekerDashBoardPages/ForgotPasswordPages/DesktopEnterEmail";
import DesktopResetPasswordenterNewPass from "pages/JobSeekerDashBoardPages/ForgotPasswordPages/DesktopForgotPasswordPage";
import MobResetPasswordemailAddress from "pages/JobSeekerDashBoardPages/ForgotPasswordPages/MobileEnterEmail";
import MobResetPasswordenterNewPass from "pages/JobSeekerDashBoardPages/ForgotPasswordPages/MobileResetPassword";
import TabResetPasswordemailAddress from "pages/JobSeekerDashBoardPages/ForgotPasswordPages/TabletEnterEmail";
import TabResetPasswordenterNewPass from "pages/JobSeekerDashBoardPages/ForgotPasswordPages/TabletResetPassword";
import InterviewPreparation from "pages/JobSeekerDashBoardPages/InterviewPreparation";
import JobSearch from "pages/JobSeekerDashBoardPages/JobSearch";
import Dashboard from "pages/JobSeekerDashBoardPages/JobseekerDashboard";
import JobseekersCheckout from "pages/JobSeekerDashBoardPages/BillingOverview/checkout";
import DesktopRecruiterRoboSignInlogIn from "pages/JobSeekerDashBoardPages/LoginPages/DesktopLoginPage";
import MobRecruiterRoboSignInlogIn from "pages/JobSeekerDashBoardPages/LoginPages/MobileLoginPage";
import TabRecruiterRoboSignInlogIn from "pages/JobSeekerDashBoardPages/LoginPages/TabletLoginPage";
import ProfilePreference from "pages/JobSeekerDashBoardPages/ProfilePreference";
import ProfileUpload from "pages/JobSeekerDashBoardPages/ProfileUpload";
import ProfilepersonalShrashti from "pages/JobSeekerDashBoardPages/Profilepersonal";
import ResumeBuilder from "pages/JobSeekerDashBoardPages/ResumeBuilder";
import ResumeDetails from "pages/JobSeekerDashBoardPages/ResumeDetails";
import ResumeDowload from "pages/JobSeekerDashBoardPages/ResumeDownload";
import SignupDesktop from "pages/JobSeekerDashBoardPages/SignupPages/DesktopSighupPage";
import MobSignupMobile from "pages/JobSeekerDashBoardPages/SignupPages/MobileSignupPage";
import SignupTablet from "pages/JobSeekerDashBoardPages/SignupPages/TabletSignup";
import AboutUs from "pages/LandingPage/AboutUs";
import BookDemo from "pages/LandingPage/BookDemo";
import Careers from "pages/LandingPage/Careers";
import ContactUs from "pages/LandingPage/ContactUs";
import Desktop1520plusMadeOn1920 from "pages/LandingPage/Desktop";
import LandingPageLaptop1220 from "pages/LandingPage/Laptop/LandingPageLaptop1220";
import FinalMobile390To820 from "pages/LandingPage/Mobile/FinalMobile390To820";
import NotFound from "pages/LandingPage/NotFound";
import Policies from "pages/LandingPage/Policies";
import PublicRoute from "pages/LandingPage/PublicRoute";
import MinWidth820AndMaxWidth12 from "pages/LandingPage/Tablet/MinWidth820AndMaxWidth12";
import TermsOfUse from "pages/LandingPage/TermsOfUse";
import ResumestatsPage from "pages/RecruiterDashBoardPages/Analytics";
import BillingHistoryRecruiter from "pages/RecruiterDashBoardPages/BillingHistoryRecruiter";
import BillingOverviewRecruiter from "pages/RecruiterDashBoardPages/BillingOverviewRecruiter";
import BillingPreferencesRecruiter from "pages/RecruiterDashBoardPages/BillingPreferencesRecruiter";
import CandidatesPage from "pages/RecruiterDashBoardPages/Candidates";
import CandidatesAddCandidate from "pages/RecruiterDashBoardPages/CandidatesAddcandidate";
import CandidatesJobPage from "pages/RecruiterDashBoardPages/JobViewCandidates";
import JobsPage from "pages/RecruiterDashBoardPages/Jobs";
import RecruiterDashboard from "pages/RecruiterDashBoardPages/RecruiterDashboard";
import RecruiterTabResetPasswordemailAddress from "pages/RecruiterDashBoardPages/RecruiterFogetPassword/recruiterforgetpasstab";
import DesktopRecruiterlogInPage from "pages/RecruiterDashBoardPages/RecruiterLoginpage/desktoploginpage";
import MobRecruiterSignInlogIn from "pages/RecruiterDashBoardPages/RecruiterLoginpage/mobileloginpage";
import TabRecruiterSignInlogIn from "pages/RecruiterDashBoardPages/RecruiterLoginpage/tabletloginpage";
import RecruiterSignupDesktop from "pages/RecruiterDashBoardPages/RecruiterSignup/desktopsignuppage";
import RecruiterMobSignupMobile from "pages/RecruiterDashBoardPages/RecruiterSignup/mobilesignuppage";
import RecruiterSignupTablet from "pages/RecruiterDashBoardPages/RecruiterSignup/tabsignuppage";
import SettingsTeam from "pages/RecruiterDashBoardPages/SettingsTeam";
import SettingsWorkFlow from "pages/RecruiterDashBoardPages/SettingsWorkFlow";
import UniversityCommunication from "pages/UniversityDashBoardPages/UniversityCommunication";
import UniversityCompanyProfile from "pages/UniversityDashBoardPages/UniversityCompanyProfile";
import UniversityDashboard from "pages/UniversityDashBoardPages/UniversityDashboard";
import UniversityFeedback from "pages/UniversityDashBoardPages/UniversityFeedback";
import UniversityDesktopResetPasswordemailAddress from "pages/UniversityDashBoardPages/UniversityFogetPassword/unirecruiterforgetpassdesk";
import UniversityMobResetPasswordemailAddress from "pages/UniversityDashBoardPages/UniversityFogetPassword/unirecruiterforgetpassmob";
import UniversityTabResetPasswordemailAddress from "pages/UniversityDashBoardPages/UniversityFogetPassword/unirecruiterforgetpasstab";
import UniversityHelpAndSupport from "pages/UniversityDashBoardPages/UniversityHelpAndSupport";
import UniversityInterviewingScheduling from "pages/UniversityDashBoardPages/UniversityInterviewingScheduling";
import UniversityDesktoplogInPage from "pages/UniversityDashBoardPages/UniversityLoginpage/unidesktoploginpage";
import UniversityMobSignInlogIn from "pages/UniversityDashBoardPages/UniversityLoginpage/unimobileloginpage";
import UniversityTabSignInlogIn from "pages/UniversityDashBoardPages/UniversityLoginpage/unitabletloginpage";
import UniversityPlacementRecords from "pages/UniversityDashBoardPages/UniversityPlacementRecords";
import UniversitySignupDesktop from "pages/UniversityDashBoardPages/UniversitySighnup/unidesktopsignuppage";
import UniversityMobSignupMobile from "pages/UniversityDashBoardPages/UniversitySighnup/unimobilesighuppage";
import UniversitySignupTablet from "pages/UniversityDashBoardPages/UniversitySighnup/unitabsighnuppage";
import UniversityStudentManagement from "pages/UniversityDashBoardPages/UniversityStudentManagement";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AutoJob from "./pages/JobSeekerDashBoardPages/AutoJob";
import Professional from "./pages/JobSeekerDashBoardPages/ProfileProfessional";
import ProtectedRoute from "./pages/LandingPage/PrivateRoute";
import AddJob from "./pages/RecruiterDashBoardPages/AddJob";
import AfterUplaodOrFillJD from "./pages/RecruiterDashBoardPages/AfterUplaodOrFillJD";
import PostAJobContainerPage from "./pages/RecruiterDashBoardPages/ProjectManager";
import UploadJD from "./pages/RecruiterDashBoardPages/UploadJD";
import InterviewRequestPage from "pages/JobSeekerDashBoardPages/InterviewRequest";
import InstructionPage from "pages/JobSeekerDashBoardPages/CareerAdvice/instructionsPage";
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    document.body.style.zoom = "100%";
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}
const ProjectRoutes = () => {
  const { width } = useWindowSize();
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              width >= 1441 ? (
                <Desktop1520plusMadeOn1920 />
              ) : width >= 1280 ? (
                <LandingPageLaptop1220 />
              ) : width >= 1024 ? (
                <MinWidth820AndMaxWidth12 />
              ) : (
                <FinalMobile390To820 />
              )
            }
          />
          <Route exact path="/policies" element={<Policies />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/book-demo" element={<BookDemo />} />
          <Route exact path="/terms-of-use" element={<TermsOfUse />}></Route>
          <Route eact path="/careers" element={<Careers />}></Route>



          {/* Job seekers login */}
          <Route
            exact
            path="/login"
            element={
              <PublicRoute
                element={
                  width >= 1720 ? (
                    <DesktopRecruiterRoboSignInlogIn />
                  ) : width >= 820 ? (
                    <TabRecruiterRoboSignInlogIn />
                  ) : (
                    <MobRecruiterRoboSignInlogIn />
                  )
                }
              />
            }
          />

          <Route
            exact
            path="/signup"
            element={
              <PublicRoute
                element={
                  width >= 1720 ? (
                    <SignupDesktop />
                  ) : width >= 820 ? (
                    <SignupTablet />
                  ) : (
                    <MobSignupMobile />
                  )
                }
              />
            }
          />

          <Route
            exact
            path="/forgotpassword"
            element={
              <PublicRoute
                element={
                  width >= 1720 ? (
                    <DesktopResetPasswordemailAddress />
                  ) : width >= 820 ? (
                    <TabResetPasswordemailAddress />
                  ) : (
                    <MobResetPasswordemailAddress />
                  )
                }
              />
            }
          />

          <Route
            exact
            path="/newpassword"
            element={
              <PublicRoute
                element={
                  width >= 1720 ? (
                    <DesktopResetPasswordenterNewPass />
                  ) : width >= 820 ? (
                    <TabResetPasswordenterNewPass />
                  ) : (
                    <MobResetPasswordenterNewPass />
                  )
                }
              />
            }
          />

          {/* Recruiter login */}
          <Route
            exact
            path="/recruiter-login"
            element={
              width >= 1720 ? (
                <DesktopRecruiterlogInPage />
              ) : width >= 820 ? (
                <TabRecruiterSignInlogIn />
              ) : (
                <MobRecruiterSignInlogIn />
              )
            }
          />

          <Route
            exact
            path="/recruiter-signup"
            element={
              width >= 1720 ? (
                <RecruiterSignupDesktop />
              ) : width >= 820 ? (
                <RecruiterSignupTablet />
              ) : (
                <RecruiterMobSignupMobile />
              )
            }
          />

          <Route
            exact
            path="/recruiter-forgotpassword"
            element={
              width >= 1720 ? (
                <UniversityDesktoplogInPage />
              ) : width >= 820 ? (
                <RecruiterTabResetPasswordemailAddress />
              ) : (
                <UniversityMobSignInlogIn />
              )
            }
          />

          {/* University Login */}

          <Route
            exact
            path="/university-login"
            element={
              width >= 1720 ? (
                <UniversityDesktoplogInPage />
              ) : width >= 820 ? (
                <UniversityTabSignInlogIn />
              ) : (
                <UniversityMobSignInlogIn />
              )
            }
          />

          <Route
            exact
            path="/university-signup"
            element={
              width >= 1720 ? (
                <UniversitySignupDesktop />
              ) : width >= 820 ? (
                <UniversitySignupTablet />
              ) : (
                <UniversityMobSignupMobile />
              )
            }
          />

          <Route
            exact
            path="/university-forgotpassword"
            element={
              width >= 1720 ? (
                <UniversityDesktopResetPasswordemailAddress />
              ) : width >= 820 ? (
                <UniversityTabResetPasswordemailAddress />
              ) : (
                <UniversityMobResetPasswordemailAddress />
              )
            }
          />

          <Route
            path="/jobseeker-dashboard"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            exact
            path="/profile-personal"
            element={<ProtectedRoute element={<ProfilepersonalShrashti />} />}
          />

<Route
            exact
            path="/interviews"
            element={<ProtectedRoute element={<InterviewRequestPage />} />}
          />
           {/* <Route path="/instruction/:jobID" element={<InstructionPage />} /> */}
          <Route
            exact
            path="/profile-upload"
            element={<ProtectedRoute element={<ProfileUpload />} />}
          />
          <Route
            exact
            path="/autojob"
            element={<ProtectedRoute element={<AutoJob />} isPaidReq />}
          />
          <Route
            exact
            path="/jobsearch"
            element={<ProtectedRoute element={<JobSearch />} isPaidReq />}
          />
          <Route
            exact
            path="/careeradvice"
            element={<ProtectedRoute element={<CareerAdvise />} isPaidReq />}
          />
          <Route
            exact
            path="/interview-preparation"
            element={
              <ProtectedRoute element={<InterviewPreparation />} isPaidReq />
            }
          />
          <Route
            exact
            path="/coverletter"
            element={<ProtectedRoute element={<CoverLetter />} isPaidReq />}
          />
          <Route
            exact
            path="/coverletter-download"
            element={
              <ProtectedRoute element={<CoverletterDowload />} isPaidReq />
            }
          />
          <Route
            exact
            path="/resumebuilder"
            element={<ProtectedRoute element={<ResumeBuilder />} isPaidReq />}
          />

          {/* <Route
            exact
            path="/billing-payment"
            element={<ProtectedRoute element={<BillingPayment />} />}
          /> */}
          <Route
            exact
            path="/checkout"
            element={<ProtectedRoute element={<JobseekersCheckout />} />}
          />

          <Route
            exact
            path="/coverletter-details"
            element={
              <ProtectedRoute element={<CoverLetterDetails />} isPaidReq />
            }
          />
          <Route
            exact
            path="/profile-preferences"
            element={<ProtectedRoute element={<ProfilePreference />} />}
          />
          <Route
            exact
            path="/profile-professional"
            element={<ProtectedRoute element={<Professional />} />}
          />
          <Route
            exact
            path="/resume-details"
            element={<ProtectedRoute element={<ResumeDetails />} isPaidReq />}
          />
          <Route
            exact
            path="/all-resumetemplates"
            element={<AllResumeTemplates />}
          // element={<ProtectedRoute element={<AllResumeTemplates />} isPaidReq />}
          />


          <Route
            exact
            path="/resume-download"
            element={<ProtectedRoute element={<ResumeDowload />} isPaidReq />}
          />
          <Route
            exact
            path="/billing-history"
            element={<ProtectedRoute element={<BillingHistory />} />}
          />
          <Route
            exact
            path="/billing-preferences"
            element={<ProtectedRoute element={<BillingPreferences />} />}
          />
          <Route
            exact
            path="/billing-overview"
            element={<ProtectedRoute element={<BillingOverview />} />}
          />

          <Route
            exact
            path="/recruiter-dashboard"
            element={<RecruiterDashboard />}
          />

          <Route
            exact
            path="/University-dashboard"
            element={<UniversityDashboard />}
          />

          <Route
            exact
            path="/University-Student-Management"
            element={<UniversityStudentManagement />}
          />

          <Route
            exact
            path="/University-Placement-Records"
            element={<UniversityPlacementRecords />}
          />

          <Route
            exact
            path="/University-Interviewing-Scheduling"
            element={<UniversityInterviewingScheduling />}
          />

          <Route
            exact
            path="/University-Help-And-Support"
            element={<UniversityHelpAndSupport />}
          />

          <Route
            exact
            path="/University-Communication"
            element={<UniversityCommunication />}
          />

          <Route
            exact
            path="/University-Feedback"
            element={<UniversityFeedback />}
          />

          <Route
            exact
            path="/University-Company-Profile"
            element={<UniversityCompanyProfile />}
          />

          <Route exact path="/candidates" element={<CandidatesPage />} />

          <Route
            exact
            path="/candidates-addcandidate"
            element={<CandidatesAddCandidate />}
          />

          <Route exact path="/add-job" element={<AddJob />} />

          <Route exact path="/jobs" element={<JobsPage />} />

          <Route exact path="/billing-history" element={<BillingHistory />} />

          <Route
            exact
            path="/billing-preferences"
            element={<BillingPreferences />}
          />

          {/* <Route exact path="/billing-payment" element={<BillingPayment />} /> */}

          <Route exact path="/billing-overview" element={<BillingOverview />} />

          <Route exact path="/settings-team" element={<SettingsTeam />} />

          <Route
            exact
            path="/settings-workflow"
            element={<SettingsWorkFlow />}
          />

          <Route exact path="/upload-jd" element={<UploadJD />} />

          <Route exact path="/addjob-jd" element={<AfterUplaodOrFillJD />} />

          <Route
            exact
            path="/addjob-workflow"
            element={<PostAJobContainerPage />}
          />

          <Route exact path="/analytics" element={<ResumestatsPage />} />

          <Route
            exact
            path="/recruiter/billing-overview"
            element={<BillingOverviewRecruiter />}
          />

          <Route
            exact
            path="/recruiter/billing-history"
            element={<BillingHistoryRecruiter />}
          />

          {/* <Route
            exact
            path="/recruiter/billing-payment"
            element={<BillingPaymentRecruiter />}
          /> */}

          <Route
            exact
            path="/recruiter/billing-preferences"
            element={<BillingPreferencesRecruiter />}
          />

          <Route exact path="/jobs/cadidates" element={<CandidatesJobPage />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
