import { Img, Button } from "components"; // Assuming Button component is available
import { useState, useEffect } from "react";


const TextToSpeech = ({ text,marks,handleStartAnswer,index }) => {
  const [repeatQuestion, setRepeat] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    setRepeat(false);
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    //console.log("entered tts");
    const voices = synth.getVoices();
    const davidVoice = voices.find(
      (voice) =>
        voice.name === "Microsoft David Desktop - English (United States)",
    );

    u.voice = davidVoice;
    synth.speak(u);
    u.onend = () => {
      setRepeat(true);
      handleStartAnswer();
      setVideoReady(true); // Marking video as ready after speech ends
    };

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handleRepeatClick = () => {
    if (repeatQuestion) {
      setRepeat(false);
      const synth = window.speechSynthesis;
      const u = new SpeechSynthesisUtterance(text);

      const voices = synth.getVoices();
      const davidVoice = voices.find(
        (voice) =>
          voice.name === "Microsoft David Desktop - English (United States)",
      );

      u.voice = davidVoice;
      synth.speak(u);
      u.onend = () => {
        setRepeat(true);
        setVideoReady(true);
      };
    }
  };

  const handleDownloadVideo = () => {
    alert("Downloading video...");
  };
  const handleStopSpeech = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    handleStartAnswer();
    setRepeat(true);
    // setSpeaking(false); // Marking speaking as false
  };
  return (

    <div className="flex pr-[2.5%] w-full flex-row justify-start items-center pt-[10px] sm:w-full" >
      <p className="sm:text-[14px] pl-[2.5%] text-gray-500 text-lg">
       {index+1}. {text} {marks&&<span>( {marks} marks )</span>}
      </p>
      {repeatQuestion ? 
          <Img className="pl-[20px]" src="images/speeker.svg" onClick={handleRepeatClick}/>
         :<Img className="pl-[20px]" src="images/recordDisc.svg" onClick={handleStopSpeech}/>
      }
    </div>
  );
};

export default TextToSpeech;
